import { makeVar } from "@apollo/client";

export const FILTER_SELECTED_ACCOUNTS = makeVar([]);

export const setFilterSelectedAccounts = (account) => {
  // const isExist = FILTER_SELECTED_ACCOUNTS().includes(account);
  // // if (!isExist) FILTER_SELECTED_ACCOUNTS([...FILTER_SELECTED_ACCOUNTS(), account]);
  // // else FILTER_SELECTED_ACCOUNTS(FILTER_SELECTED_ACCOUNTS().filter((a) => a !== account));
  // if (isExist) FILTER_SELECTED_ACCOUNTS([]);
  // else
  FILTER_SELECTED_ACCOUNTS(!account ? [] : [account]);
};

export const resetMetricsReactiveVars = () => {
  FILTER_SELECTED_ACCOUNTS([]);
};
