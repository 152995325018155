import { gql } from "@apollo/client";

export const GET_METRICS = gql`
  query getMetrics {
    metrics {
      id
      name
      dataType
    }
  }
`;

export const GET_METRICS_DATA = gql`
  query getMetricsData($platformMetricId: ID!, $platformTargetIds: [ID], $startAt: String, $endAt: String) {
    metricsData(
      platformMetricId: $platformMetricId
      platformTargetIds: $platformTargetIds
      startAt: $startAt
      endAt: $endAt
    )
  }
`;

export const FETCH_METRICS = gql`
  query fetchMetrics($platformTargetIds: [ID], $startAt: String, $endAt: String) {
    fetchMetrics(platformTargetIds: $platformTargetIds, startAt: $startAt, endAt: $endAt)
  }
`;

export const GET_AD_METRICS_DATA = gql`
  query getAdMetricsData($platformTargetIds: [ID], $campaignIds: [ID], $startAt: String, $endAt: String) {
    adMetricsData(platformTargetIds: $platformTargetIds, campaignIds: $campaignIds, startAt: $startAt, endAt: $endAt)
  }
`;

export const GET_METRICS_DATA_BY_INTERVALS = gql`
  query getMetricsDataByIntervals(
    $platformMetricId: ID!
    $platformTargetIds: [ID]
    $startAt: String
    $endAt: String
    $interval: String
  ) {
    metricsDataByIntervals(
      platformMetricId: $platformMetricId
      platformTargetIds: $platformTargetIds
      startAt: $startAt
      endAt: $endAt
      interval: $interval
    )
  }
`;
