import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, split } from "@apollo/client";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import * as Sentry from "@sentry/react";
import { LocalForageWrapper, persistCache } from "apollo3-cache-persist";
import Gleap from "gleap";
import { createClient } from "graphql-ws";
import LocalForge from "localforage";
import LogRocket from "logrocket";
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";

import App from "./app";
import typePolicies from "./graphql/typePolicies";
import "./styles/index.scss";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.MODE,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", import.meta.env.VITE_SITE_URL],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  profilesSampleRate: 1.0, // Capture 100% of the profiles
});

if (import.meta.env.VITE_LOGROCKET_APP_ID) {
  LogRocket.init(import.meta.env.VITE_LOGROCKET_APP_ID);
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.setExtra("logrocket", sessionURL);
  });
}

// eslint-ignore-next-line
console.info(`GrowMetrics Client version: ${import.meta.env.VITE_APP_VERSION}`);

const cache = createCache({ key: "css" });
cache.compat = true; // To disable the server-rendering-unsafe-selector warning

const url = import.meta.env.VITE_GRAPHQL_WS_URL;
const ddToken = import.meta.env.VITE_DATADOG_CLIENT_TOKEN;

let activeSocket;
let timedOut;
const wsLink = new GraphQLWsLink(
  createClient({
    on: {
      closed: (e) => {
        console.info(`Graphql socket closed`);
      },
      connected: (e) => {
        activeSocket = e;
        console.info(`Graphql socket connected`);
      },
      connecting: (e) => {
        console.info(`Graphql socket connecting...`);
      },
      ping: (received) => {
        if (!received)
          // sent
          timedOut = setTimeout(() => {
            if (activeSocket.readyState === WebSocket.OPEN) activeSocket.close(4408, "Request Timeout");
          }, 5000); // wait 5 seconds for the pong and then close the connection
      },
      pong: (received) => {
        if (received) clearTimeout(timedOut); // pong is received, clear connection close timeout
      },
    },
    keepAlive: 10000,
    retryAttempts: 10,
    shouldRetry: (error) => !!error,
    url,
  })
);

const httpLink = new HttpLink({
  credentials: "include",
  uri: import.meta.env.VITE_GRAPHQL_URL,
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  wsLink,
  httpLink
);

const apolloCache = new InMemoryCache({
  typePolicies,
});

LocalForge.setDriver(LocalForge.INDEXEDDB);
LocalForge.config({
  name: "growmetrics",
  storeName: "apollo-persist-cache",
});

persistCache({
  cache: apolloCache,
  storage: new LocalForageWrapper(LocalForge),
  maxSize: false,
  serialize: false,
  key: "gql-cache",
}).then(() => {
  const apolloClient = new ApolloClient({
    cache: apolloCache,
    connectToDevTools: import.meta.env.MODE === "development",
    defaultOptions: {
      mutate: {
        fetchPolicy: "network-only",
      },
      query: {
        fetchPolicy: "cache-first",
      },
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
    link,
  });

  if (import.meta.env.MODE !== "development") {
    datadogLogs.init({
      clientToken: ddToken,
      site: "datadoghq.com",
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
    });

    datadogRum.init({
      applicationId: import.meta.env.VITE_DATADOG_APP_ID,
      clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
      site: "datadoghq.com",
      service: "webapp",
      env: import.meta.env.MODE,
      // FIXME
      version: import.meta.env.VITE_APP_VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    });
  } else if (import.meta.env.MODE === "development") {
    loadDevMessages();
    loadErrorMessages();
  }
  if (import.meta.env.VITE_GLEAP_API_KEY) Gleap.initialize(import.meta.env.VITE_GLEAP_API_KEY);

  const script = document.createElement("script");
  script.src = "https://js.chargebee.com/v2/chargebee.js";
  script.setAttribute("data-cb-site", import.meta.env.VITE_CHARGEBEE_SITE);
  document.head.appendChild(script);

  const toltScript = document.createElement("script");
  toltScript.async = true;
  toltScript.src = import.meta.env.VITE_TOLT_SCRIPT_URL;
  toltScript.setAttribute("data-tolt", import.meta.env.VITE_TOLT_API_ID);
  document.head.appendChild(toltScript);

  window.addEventListener("load", function () {
    try {
      window.Chargebee.init({
        site: import.meta.env.VITE_CHARGEBEE_SITE,
        publishableKey: import.meta.env.VITE_PUBLISHABLE_KEY,
      });
    } catch (error) {
      console.error(error);
    }
  });

  ReactDOM.createRoot(document.getElementById("root")).render(
    <ApolloProvider client={apolloClient}>
      <CacheProvider value={cache}>
        <App />
      </CacheProvider>
    </ApolloProvider>
  );
});
