import { useReactiveVar, useSubscription } from "@apollo/client";

import { CURRENT_ACCOUNT } from "../graphql/localStates/account";
import { SOCKET_ID } from "../graphql/localStates/root";

/**
 *
 * @param {import("graphql").DocumentNode | import("@apollo/client").TypedDocumentNode} subscription
 * @param {import("@apollo/client").SubscriptionHookOptions} options
 * @returns {import("@apollo/client").SubscriptionResult}
 */

const useSubscriptionWithContext = (subscription, options = {}) => {
  const accountId = useReactiveVar(CURRENT_ACCOUNT);
  const socketId = useReactiveVar(SOCKET_ID);

  const subscriptionName = subscription?.definitions?.[0]?.name?.value;

  return useSubscription(subscription, {
    onError: (error) => {
      console.error(`useSubscriptionWithContext[${subscriptionName ?? "Unknown"}] - `, error);
    },
    ...options,
    shouldResubscribe: !!options.shouldResubscribe ?? true,
    variables: {
      ...options.variables,
      context: {
        socketId,
        accountId,
      },
    },
  });
};

export default useSubscriptionWithContext;
