import { makeVar } from "@apollo/client";

export const EDIT_POST_SELECTED_PLATFORM_TARGETS = makeVar([]);
export const EDIT_POST_SELECTED_PLATFORMS = makeVar([]);

export const SHOW_MEDIA_LIBRARY = makeVar(false);
export const setShowMediaLibrary = (state) => SHOW_MEDIA_LIBRARY(state);

export const setSelectedPlatformTargets = (selectedPlatformTargets) => {
  EDIT_POST_SELECTED_PLATFORM_TARGETS(selectedPlatformTargets);
  const platforms = new Set([...selectedPlatformTargets.map((platformTarget) => platformTarget.platform)]);
  EDIT_POST_SELECTED_PLATFORMS([...platforms]);
};

export const clearSelectedPlatformTargets = () => {
  EDIT_POST_SELECTED_PLATFORM_TARGETS([]);
  EDIT_POST_SELECTED_PLATFORMS([]);
  SHOW_MEDIA_LIBRARY(false);
};

export const resetPostReactiveVars = () => {
  EDIT_POST_SELECTED_PLATFORMS([]);
  EDIT_POST_SELECTED_PLATFORM_TARGETS([]);
  SHOW_MEDIA_LIBRARY(false);
};
