import { gql } from "@apollo/client";

const GET_IN_APP_NOTIFICATIONS = gql`
  query getInAppNotifications($accountId: ID!) {
    inAppNotifications(accountId: $accountId) {
      id
      accountId
      payload
      createdAt
      isRead
    }
  }
`;

const GET_NOTIFICATION_PREFERENCES = gql`
  query notificationPreferences($accountId: ID!) {
    notificationPreferences(accountId: $accountId) {
      id
      name
      parentId
      methods {
        id
        name
        typeMethodId
        isSubscribed
      }
      description
      children {
        id
        name
        parentId
        methods {
          id
          name
          typeMethodId
          isSubscribed
        }
        description
      }
    }
  }
`;

export { GET_IN_APP_NOTIFICATIONS, GET_NOTIFICATION_PREFERENCES };
