import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { colors } from "../../styles/theme";
import { BodySmallBold } from "./typography";

export const Tabs = styled.nav`
  width: 100%;
  display: flex;
  border-bottom: solid 1px ${colors.gray[500]};
  overflow: auto;
  flex-shrink: 0;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Tab = styled(BodySmallBold.withComponent("button"))`
  cursor: pointer;
  padding: 8px;
  color: ${colors.gray[700]};
  background-color: transparent;
  outline: none;
  white-space: nowrap;
  ${(props) => props.stretch && "flex: 1"};
  flex-shrink: 0;
  min-width: max-content;

  &::first-letter {
    text-transform: uppercase;
  }

  ${(props) =>
    props.selected &&
    css`
      color: ${colors.purple[1000]};
      background-color: ${colors.purple[200]};
      box-shadow: 0 -4px 0 ${colors.purple[1000]} inset;
    `}

  &:hover {
    ${(props) =>
      !props?.selected &&
      css`
        color: ${colors.gray[800]};
        background-color: ${colors.purple[100]};
        box-shadow: 0 -4px 0 ${colors.purple[500]} inset;
      `}
  }

  &:focus {
    ${(props) =>
      !props.selected &&
      css`
        box-shadow: 0px 0px 0px 2px ${colors.purple[900]} inset;
      `}
  }

  &:active {
    color: ${colors.gray[900]};
    background-color: ${colors.purple[100]};
    box-shadow: 0 -4px 0 ${colors.purple[600]} inset;
  }

  &:disabled {
    color: ${colors.gray[600]};
  }
`;
