import { useApolloClient, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import Gleap from "gleap";
import LogRocket from "logrocket";
import { useState } from "react";
import { Navigate, Outlet, useMatch } from "react-router-dom";

import { FullWindowLoader } from "../components/common/loaders";
import paths from "../config/paths";
import { GET_USER } from "../graphql/queries/user";
import useDeepCompareEffect from "../hooks/use-deep-compare-effect";
import gleap from "../lib/gleap-helper";

const Auth = () => {
  const apolloClient = useApolloClient();
  const isNewUserPath = useMatch(paths.newUser);
  const [retry, setRetry] = useState(false);
  const { data, error, loading } = useQuery(GET_USER, {
    fetchPolicy: "network-only",
    onCompleted: () => setRetry(false),
    onError: () => setRetry(true),
    pollInterval: retry ? 3000 : 1000 * 60 * 10,
  });

  useDeepCompareEffect(() => {
    if (!data?.user) return;
    gleap.identify(data?.user);
    LogRocket.identify(data?.user?.id, {
      name: data.user.name,
      email: data.user.email,
    });
    Sentry.getGlobalScope().setUser({ id: data.user.id, email: data.user.email });
  }, [data]);

  if (error && error?.graphQLErrors?.find((err) => err.extensions.code === "UNAUTHENTICATED")) {
    apolloClient.clearStore();
    Gleap.clearIdentity();
    return <Navigate to={paths.login} />;
  }

  if (!data?.user || loading) return <FullWindowLoader />;
  if (!isNewUserPath && data.user.isNew) return <Navigate to={paths.newUser} />;
  return <Outlet />;
};

export default Auth;
