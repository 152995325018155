import { css } from "@emotion/react";
import styled from "@emotion/styled";

import convertComponent from "../../lib/convert-component";
import { colors, elevation } from "../../styles/theme";
import { SmallDarkButton } from "./buttons";
import { OutlinedIcon } from "./icons";
import { MiniBold } from "./typography";

const selectedLightChipCss = (props) => css`
  color: ${colors[props.color][900]};
  background-color: ${colors[props.color][300]};
`;

const disabledLightChipCss = css`
  background-color: ${colors.gray[400]};
  color: ${colors.gray[600]};
`;

const LightChip = styled(MiniBold.withComponent("div"))`
  max-width: min-content;
  padding: 4px 12px;
  font-weight: 800;
  border-radius: 100px;
  background-color: ${(props) => colors[props.color][100]};
  color: ${(props) => colors[props.color][800]};
  white-space: nowrap;
  box-shadow: ${elevation[100]};

  &:hover {
    background-color: ${(props) => colors[props.color][200]};
  }

  &:focus {
    box-shadow: inset 0px 0px 0px 2px ${(props) => colors[props.color][800]};
  }

  &:active:hover {
    color: ${(props) => colors[props.color][900]};
    background-color: ${(props) => colors[props.color][200]};
  }

  &:disabled {
    ${disabledLightChipCss}
  }

  ${(props) => props.isDisabled && disabledLightChipCss}

  &:active {
    ${selectedLightChipCss}
  }

  ${(props) => props.isSelected && selectedLightChipCss}
`;

const TinyLightChip = styled(LightChip)`
  width: max-content;
  max-width: max-content;
  max-height: min-content;
  padding: 1px 8px 1px 4px;
  font-weight: 400;
  display: flex;
  gap: 4px;
`;

const Container = styled(convertComponent(SmallDarkButton, "div"))`
  cursor: auto;
  margin-right: 4px;
  gap: 4px;
`;

const RemoveIcon = styled(OutlinedIcon)`
  cursor: pointer;
`;

const RemovableChip = ({ remove, children, className, disabled }) => (
  <Container backgroundColor="gray" className={className}>
    {children}
    {!disabled && <RemoveIcon name="close" onClick={remove} size="18px" />}
  </Container>
);

export { LightChip, TinyLightChip, RemovableChip };
