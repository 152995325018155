import { css } from "@emotion/react";

const scrollable = css`
  flex: 1 1 0;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const ellipsisOverflow = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export { scrollable, ellipsisOverflow };
