import { gql } from "@apollo/client";

export const TOGGLE_PLATFORM_TARGET = gql`
  mutation togglePlatformTarget($id: ID!, $isEnabled: Boolean!) {
    togglePlatformTarget(id: $id, isEnabled: $isEnabled) {
      id
      isEnabled
    }
  }
`;

export const DISCONNECT_PLATFORM_TARGETS = gql`
  mutation disconnectPlatformTargets($ids: [ID!]!) {
    disconnectPlatformTargets(ids: $ids)
  }
`;
