import { useApolloClient, useMutation, useReactiveVar } from "@apollo/client";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useState } from "react";
import { Link } from "react-router-dom";

import paths from "../../config/paths";
import { CURRENT_ACCOUNT, SET_CURRENT_ACCOUNT } from "../../graphql/localStates/account";
import { ACCEPT_INVITE } from "../../graphql/mutations/account";
import { GET_ACCOUNTS } from "../../graphql/queries/user";
import convertComponent from "../../lib/convert-component";
import { colors, elevation } from "../../styles/theme";
import { MediumOutlinedButton } from "../common/buttons";
import Card from "../common/cards";
import { ellipsisOverflow } from "../common/css-snippets";
import { ClickableOutlinedIcon, OutlinedIcon } from "../common/icons";
import { Tab, Tabs } from "../common/tabs";
import { Body, BodySmallBold, Caption } from "../common/typography";

const Content = styled.div`
  padding: 16px 16px;
  overflow: auto;
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const CreateAccountLink = styled(convertComponent(MediumOutlinedButton, Link))`
  margin: 0 0 16px;
  text-align: right;
  text-transform: uppercase;
`;

const Role = styled(Caption)`
  text-transform: capitalize;
`;

const CardContainer = styled(Card)`
  margin-bottom: 8px;
  background-color: ${colors.blue[100]};
  box-shadow: ${elevation[200]};

  ${Caption} {
    color: ${colors.gray[700]};
  }

  ${(props) =>
    props.isSelected &&
    css`
      background-color: ${colors.blue[300]};

      ${BodySmallBold} {
        color: ${colors.blue[1000]};
      }

      ${Caption} {
        color: ${colors.gray[900]};
      }
    `}
`;

const ClickableCardContainer = styled(CardContainer)`
  cursor: pointer;

  &:hover {
    background-color: ${colors.blue[200]};

    ${BodySmallBold} {
      color: ${colors.gray[1000]};
    }

    ${Caption}, ${Role} {
      color: ${colors.gray[800]};
    }
  }
`;

const TextDiv = styled.div`
  width: 100%;
  min-height: 36px;
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  ${ellipsisOverflow}
`;

const SelectAccount = ({ currentAccount, showInvitePanel, setShowInvitePanel, accounts }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <>
      <Tabs>
        <Tab onClick={() => setSelectedTab(0)} selected={selectedTab === 0} stretch>
          Your spaces
        </Tab>
        <Tab onClick={() => setSelectedTab(1)} selected={selectedTab === 1} stretch>
          Invites
        </Tab>
      </Tabs>

      {selectedTab === 0 && (
        <YourSpaces
          accounts={accounts.accepted}
          currentAccount={currentAccount}
          setShowInvitePanel={setShowInvitePanel}
          showInvitePanel={showInvitePanel}
        />
      )}

      {selectedTab === 1 && <Invites accounts={accounts.pending} />}
    </>
  );
};

export default SelectAccount;

const YourSpaces = ({ accounts, showInvitePanel, setShowInvitePanel }) => {
  const client = useApolloClient();
  const showInviteButton = true;
  const currentAccount = useReactiveVar(CURRENT_ACCOUNT);

  const onClick = (accountId) => {
    if (accountId === currentAccount) {
      setShowInvitePanel((prev) => !prev);
    } else {
      SET_CURRENT_ACCOUNT(accountId, client);
      setShowInvitePanel(false);
    }
  };

  return (
    <Content>
      <CreateAccountLink color="blue" shade={800} to={paths.createAccount}>
        <OutlinedIcon name="add" size="18px" />
        Create new space
      </CreateAccountLink>

      {accounts.map((account) => {
        return (
          <ClickableCardContainer
            isSelected={account.id === currentAccount}
            key={account.id}
            onClick={() => onClick(account.id)}
          >
            <Card.Content>
              <OutlinedIcon name="store" />
              <TextDiv>
                <BodySmallBold>{account.name}</BodySmallBold>
                <Role>{account.role}</Role>
              </TextDiv>
            </Card.Content>
            {account.id === currentAccount ? (
              <ClickableOutlinedIcon
                color="blue"
                isSelected={account.id === currentAccount}
                name={showInvitePanel ? "arrow_circle_left" : "person_add"}
                shade={800}
              />
            ) : (
              <ClickableOutlinedIcon color="blue" name="swap_horiz" shade={800} />
            )}
          </ClickableCardContainer>
        );
      })}
    </Content>
  );
};

const CardIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Invites = ({ accounts }) => {
  const [acceptInvite, { loading: acceptLoading }] = useMutation(ACCEPT_INVITE, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_ACCOUNTS }],
  });

  const accept = async (accountId) => {
    await acceptInvite({ variables: { accountId } });
  };

  return (
    <Content>
      {accounts.map((account) => (
        <CardContainer key={account.id}>
          <Card.Content>
            <OutlinedIcon name="store" />
            <TextDiv>
              <BodySmallBold>{account.name}</BodySmallBold>
              <Caption>Invited by {account.inviter}</Caption>
            </TextDiv>
          </Card.Content>

          <CardIcons>
            <ClickableOutlinedIcon color="red" name="remove_circle" shade={800} />
            <ClickableOutlinedIcon
              isDisabled={acceptLoading}
              color="green"
              name="check_circle"
              onClick={() => accept(account.id)}
              shade={800}
            />
          </CardIcons>
        </CardContainer>
      ))}

      {accounts.length === 0 && <Body>You don&apos;t have any pending invitations.</Body>}
    </Content>
  );
};
