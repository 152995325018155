import styled from "@emotion/styled";

import igCommentGray900 from "../../assets/icons/ig-comment-gray-900.svg";
import igShareGray900 from "../../assets/icons/ig-share-gray-900.svg";
import PencilCircleGray800 from "../../assets/icons/pencil-circle-gray-800.svg";
import PencilCircleGray900 from "../../assets/icons/pencil-circle-gray-900.svg";
import FacebookCustom700 from "../../assets/icons/platforms/facebook-custom-700.svg";
import FacebookGray700 from "../../assets/icons/platforms/facebook-gray-700.svg";
import FacebookGray900 from "../../assets/icons/platforms/facebook-gray-900.svg";
import FacebookPurple1000 from "../../assets/icons/platforms/facebook-purple-1000.svg";
import FacebookWhite from "../../assets/icons/platforms/facebook-white.svg";
import GoogleGray700 from "../../assets/icons/platforms/google-gray-700.svg";
import GoogleGray900 from "../../assets/icons/platforms/google-gray-900.svg";
import GoogleMulti from "../../assets/icons/platforms/google-multi.svg";
import GoogleWhite from "../../assets/icons/platforms/google-white.svg";
import GymLeadsMulti from "../../assets/icons/platforms/gymleads-multi.svg";
import GymSalesMulti from "../../assets/icons/platforms/gymsales-multi.svg";
import InstagramGray700 from "../../assets/icons/platforms/instagram-gray-700.svg";
import InstagramGray900 from "../../assets/icons/platforms/instagram-gray-900.svg";
import InstagramMulti from "../../assets/icons/platforms/instagram-multi.svg";
import InstagramPurple1000 from "../../assets/icons/platforms/instagram-purple-1000.svg";
import InstagramWhite from "../../assets/icons/platforms/instagram-white.svg";
import LinkedinCustom from "../../assets/icons/platforms/linkedin-custom.svg";
import LinkedinGray700 from "../../assets/icons/platforms/linkedin-gray-700.svg";
import LinkedinGray900 from "../../assets/icons/platforms/linkedin-gray-900.svg";
import LinkedinPurple1000 from "../../assets/icons/platforms/linkedin-purple-1000.svg";
import LinkedinWhite from "../../assets/icons/platforms/linkedin-white.svg";
import MindbodyBlack from "../../assets/icons/platforms/mindbody-black.svg";
import MindbodyGray700 from "../../assets/icons/platforms/mindbody-gray-700.svg";
import TikTokBlack from "../../assets/icons/platforms/tiktok-black.svg";
import TikTokGray700 from "../../assets/icons/platforms/tiktok-gray-700.svg";
import TikTokGray900 from "../../assets/icons/platforms/tiktok-gray-900.svg";
import TikTokPurple1000 from "../../assets/icons/platforms/tiktok-purple-1000.svg";
import TikTokWhite from "../../assets/icons/platforms/tiktok-white.svg";
import TwitterCustom700 from "../../assets/icons/platforms/twitter-custom-700.svg";
import TwitterGray700 from "../../assets/icons/platforms/twitter-gray-700.svg";
import TwitterGray900 from "../../assets/icons/platforms/twitter-gray-900.svg";
import TwitterPurple1000 from "../../assets/icons/platforms/twitter-purple-1000.svg";
import TwitterWhite from "../../assets/icons/platforms/twitter-white.svg";
import { OutlinedIcon } from "./icons";

const SVGS = {
  facebook: {
    custom: { 700: FacebookCustom700 },
    gray: { 700: FacebookGray700, 900: FacebookGray900 },
    purple: { 1000: FacebookPurple1000 },
    white: FacebookWhite,
  },
  google: {
    gray: { 700: GoogleGray700, 900: GoogleGray900 },
    multi: GoogleMulti,
    white: GoogleWhite,
  },
  instagram: {
    gray: { 700: InstagramGray700, 900: InstagramGray900 },
    multi: InstagramMulti,
    purple: { 1000: InstagramPurple1000 },
    white: InstagramWhite,
  },
  linkedin: {
    custom: LinkedinCustom,
    gray: { 700: LinkedinGray700, 900: LinkedinGray900 },
    purple: { 1000: LinkedinPurple1000 },
    white: LinkedinWhite,
  },
  mindbody: {
    black: MindbodyBlack,
    gray: { 700: MindbodyGray700 },
  },
  pencil_circle: {
    gray: { 800: PencilCircleGray800, 900: PencilCircleGray900 },
  },
  tiktok: {
    black: TikTokBlack,
    gray: { 700: TikTokGray700, 900: TikTokGray900 },
    purple: { 1000: TikTokPurple1000 },
    white: TikTokWhite,
  },
  gymsales: {
    black: GymSalesMulti,
    gray: { 700: GymSalesMulti, 900: GymSalesMulti },
    purple: { 1000: GymSalesMulti },
    white: GymSalesMulti,
    multi: GymSalesMulti,
  },
  gymleads: {
    black: GymLeadsMulti,
    gray: { 700: GymLeadsMulti, 900: GymLeadsMulti },
    purple: { 1000: GymLeadsMulti },
    white: GymLeadsMulti,
    multi: GymLeadsMulti,
  },
  twitter: {
    custom: { 700: TwitterCustom700 },
    gray: { 700: TwitterGray700, 900: TwitterGray900 },
    purple: { 1000: TwitterPurple1000 },
    white: TwitterWhite,
  },
  igComment: {
    gray: {
      900: igCommentGray900,
    },
  },
  igShare: {
    gray: {
      900: igShareGray900,
    },
  },
};

const Img = styled.img`
  /* height: ${(props) => props.size}; */
  width: ${(props) => props.size};
`;

const CustomIcon = ({ src, name, size, color, shade, className, ...props }) => {
  if (!name && !src) return <></>;
  return SVGS[name] ? (
    <Img
      className={className ?? ""}
      color={color}
      shade={shade}
      size={size || "24px"}
      src={src || (shade ? SVGS[name]?.[color]?.[shade] : SVGS[name]?.[color])}
      {...props}
    />
  ) : (
    <OutlinedIcon color={color} size={size || "24px"} shade={shade} name={name} />
  );
};

export default CustomIcon;
