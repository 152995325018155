import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";

import backgroundLight from "../assets/images/auth-background-light.svg";
import { grid } from "../styles/theme";

const Main = styled.main`
  min-height: 100vh;
  display: grid;
  padding: 0 ${grid.desktop.margin};
  grid-template-columns: repeat(${grid.desktop.cols}, 1fr);
  column-gap: ${grid.desktop.gutter};
  background-image: url(${backgroundLight});
  background-size: cover;
`;

const Unauth = () => (
  <Main>
    <Outlet />
  </Main>
);

export default Unauth;
