import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { colors } from "../../styles/theme";

const I = styled.i`
  font-size: ${(props) => props.size || "24px"};
  ${(props) => props.color && props.shade && `color: ${colors[props.color][props.shade]};`}
`;

const FilledIcon = ({ name, size, color, shade, className, children, ...props }) => (
  <I className={`material-icons	${className ?? ""}`} color={color} shade={shade} size={size} {...props}>
    {name ?? children}
  </I>
);

const OutlinedIcon = ({ name, size, color, shade, className, children, ...props }) => (
  <I className={`material-symbols-outlined ${className ?? ""}`} color={color} shade={shade} size={size} {...props}>
    {name ?? children}
  </I>
);

const ClickableI = styled.i`
  cursor: pointer;
  font-size: ${(props) => props.size || "24px"};
  ${(props) => props.color && props.shade && `color: ${colors[props.color][props.shade]};`}

  &:hover {
    ${(props) => props.color && props.shade && `color: ${colors[props.color][props.shade + 100]};`}
  }

  &:active {
    ${(props) => props.color && props.shade && `color: ${colors[props.color][props.shade + 200]};`}
  }

  ${(props) => props.isSelected && `color: ${colors[props.color][props.shade + 300]};`}

  ${(props) =>
    props.isDisabled &&
    css`
      cursor: default;
      color: ${colors.gray[600]};
      &:hover {
        color: ${colors.gray[600]};
      }
    `}
`;

const ClickableBaseIcon = ({
  type,
  name,
  size,
  color,
  shade,
  isSelected,
  isDisabled,
  onClick,
  className,
  children,
  ...props
}) => (
  <ClickableI
    className={`${type === "outlined" ? "material-symbols-outlined" : "material-icons"} ${className ?? ""}`}
    color={color}
    isDisabled={isDisabled}
    isSelected={isSelected}
    onClick={(e) => {
      if (!isDisabled) onClick?.(e);
    }}
    shade={shade}
    size={size}
    tabIndex={0}
    {...props}
  >
    {name ?? children}
  </ClickableI>
);

const ClickableOutlinedIcon = (props) => <ClickableBaseIcon type="outlined" {...props} />;
const ClickableFilledIcon = (props) => <ClickableBaseIcon type="filled" {...props} />;

export { FilledIcon, OutlinedIcon, ClickableOutlinedIcon, ClickableFilledIcon };
