import { useQuery, useReactiveVar } from "@apollo/client";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";

import paths from "../../config/paths";
import { CURRENT_ACCOUNT } from "../../graphql/localStates/account";
import { SELECTED_PLATFORM, SELECTED_PLATFORM_TARGET } from "../../graphql/localStates/platform";
import { GET_PLATFORM_TARGETS } from "../../graphql/queries/platform";
import { GET_ACCOUNTS } from "../../graphql/queries/user";
import useDeepCompareMemo from "../../hooks/use-deep-compare-memo";
import { colors, elevation } from "../../styles/theme";
import { MediumDarkButton } from "../common/buttons";
import { scrollable } from "../common/css-snippets";
import { Hr } from "../common/dividers";
import { FilledIcon, OutlinedIcon } from "../common/icons";
import SidePanel from "../common/side-panel";
import Spacer from "../common/spacer";
import { Mini, MiniBold } from "../common/typography";
import ChildPlatformTargets from "../platforms/child-targets";
import PlatformTargets from "../platforms/targets";
import Invite from "./invite";
import SelectAccount from "./select-account";

const PANELS = { none: "none", platforms: "platforms", selectAccount: "selectAccount" };
const SIDE_PANEL_WIDTH = "352px";
const SIDE_PANEL_HEADER_PADDING = "11px 16px 27px 16px";

const Container = styled.div`
  position: relative;
  z-index: 4;
`;

const Main = styled.div`
  grid-column: 1 / 1;
  height: 100%;
  max-height: 100vh;
  padding: 16px 8px 0;
  background-color: white;
  box-shadow: ${elevation[300]};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 4;

  ${scrollable}
`;

const Logo = styled.img`
  width: 100%;
  height: 100%;
`;

const LogoLink = styled(Link)`
  width: 32px;
  height: 32px;
  margin-inline: auto;
  display: block;
`;

const Nav = styled.nav`
  flex: 1 1 auto;
`;

const NavigationLink = styled(NavLink)`
  margin-bottom: 8px;
  padding: 4px;
  color: ${colors.gray[1000]};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    color: ${colors.gray[1000]};
    background-color: ${colors.purple[100]};
  }

  &:focus {
    outline: none;
    color: ${colors.purple[900]};
    background-color: ${colors.purple[100]};
    box-shadow: inset 0px 0px 0px 2px ${colors.purple[900]};
  }

  &:active:hover {
    color: ${colors.purple[900]};
    background-color: ${colors.purple[200]};
  }

  &.active {
    color: ${colors.purple[900]};
    background-color: ${colors.purple[300]};
  }
`;

const NavigationButton = styled(NavigationLink.withComponent("button"))`
  cursor: pointer;
  width: 100%;
`;

const Label = styled(MiniBold)`
  margin-top: 4px;
  text-transform: capitalize;
`;

const Divider = styled(Hr)`
  margin: 8px;
`;

const Settings = styled.menu`
  display: flex;
  flex-direction: column;
`;

const PlatformsButton = styled(MediumDarkButton)`
  margin: 0 0 12px;
`;

const AccountName = styled(Mini)`
  min-height: 16px;
  width: 73px;
  margin-top: 8px;
  color: ${colors.gray[700]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  visibility: ${(props) => (props.isCurrentAccount ? "visible" : "hidden")};
`;

const RotatingArrowIcon = styled(FilledIcon)`
  ${(props) => props.isRotate && "transform: rotate(0.5turn);"}
  transition: transform 0.05s;
`;

const SideMenu = () => {
  const { data } = useQuery(GET_ACCOUNTS);
  const currentAccountId = useReactiveVar(CURRENT_ACCOUNT);
  const selectedPlatform = useReactiveVar(SELECTED_PLATFORM);
  const selectedPlatformTarget = useReactiveVar(SELECTED_PLATFORM_TARGET);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [currentAccountName, setCurrentAccountName] = useState(null);
  const [panel, setPanel] = useState(PANELS.none);
  const [showInvitePanel, setShowInvitePanel] = useState(false);
  const [selectableAccounts, setAccounts] = useState({ accepted: [], pending: [] });

  const { data: getPlatformTargets } = useQuery(GET_PLATFORM_TARGETS, {
    variables: { accountId: currentAccountId, filters: { isEnabled: true } },
  });

  const togglePanel = (clickedPanel) => {
    const toPanel = clickedPanel === panel ? PANELS.none : clickedPanel;
    if (selectedPlatform || showInvitePanel) {
      SELECTED_PLATFORM(null);
      setShowInvitePanel(false);
      setTimeout(() => setPanel(toPanel), 500);
    } else {
      setPanel(toPanel);
    }
  };

  useEffect(() => {
    if (data?.accounts?.length) {
      setCurrentAccount(data.accounts.find((account) => account && account?.id === currentAccountId));
      setCurrentAccountName(data.accounts.find((account) => account && account?.id === currentAccountId)?.name);
      setAccounts({
        accepted: data.accounts.filter((account) => account && (account?.isAcceptedInvite || !account?.inviterUserId)),
        pending: data.accounts.filter((account) => account && !account?.isAcceptedInvite && account?.inviterUserId),
      });
    }
  }, [data, currentAccountId]);

  const closePanel = () => togglePanel(PANELS.none);

  const hasDeauthorizedPlatforms = useDeepCompareMemo(
    () => getPlatformTargets?.platformTargets?.some((platform) => platform.isDeauthorized && !platform.isDisconnected),
    [getPlatformTargets?.platformTargets]
  );

  return (
    <Container>
      <Main>
        <Nav>
          <LogoLink to={paths.accounts}>
            <Logo alt="logo" src="/logos/circular-logo.svg" />
          </LogoLink>

          <Spacer mb="8px" mt="8px" size="s" />

          <menu onClick={closePanel}>
            <NavItemLink icon="speed" label="dashboard" link={paths.dashboard} />
            <NavItemLink icon="image_search" label="drive" link={paths.drive} />

            <Divider />

            <NavItemLink icon="schedule_send" label="posts" link={paths.posts} />
            <NavItemLink icon="query_stats" label="metrics" link={paths.metrics} />

            <Divider />

            <NavItemLink icon="ads_click" label="ads assistant" link={paths.adsAssistant} />
            <NavItemLink icon="import_contacts" label="CRM" link={paths.crm} />

            <Divider />

            <NavItemLink icon="support" label="support" href={import.meta.env.VITE_SITE_HELP_URL} />
            <NavItemLink icon="school" label="tutorials" link={paths.tutorials} />
          </menu>
        </Nav>

        <Settings>
          <PlatformsButton
            backgroundColor={hasDeauthorizedPlatforms ? "red" : "green"}
            backgroundShade={hasDeauthorizedPlatforms ? 500 : 300}
            color={colors[hasDeauthorizedPlatforms ? "red" : "green"][900]}
            isSelected={panel === PANELS.platforms}
            onClick={() => togglePanel(PANELS.platforms)}
          >
            {hasDeauthorizedPlatforms ? (
              <OutlinedIcon name="priority_high" color="red" shade={900} size="20px" />
            ) : (
              getPlatformTargets?.platformTargets?.length || 0
            )}

            <RotatingArrowIcon isRotate={panel === PANELS.platforms} name="arrow_right" size="24px" />
          </PlatformsButton>

          <NavItemLink icon="account_circle" iconSize="24px" link={paths.profile} />
          {/* <SettingsButton icon="settings" iconSize="24px" /> */}

          <SettingsButton
            className={panel === PANELS.selectAccount && "active"}
            icon="apps"
            iconSize="24px"
            onClick={() => togglePanel(PANELS.selectAccount)}
          >
            <AccountName isCurrentAccount={!!currentAccountName}>{currentAccountName}</AccountName>
          </SettingsButton>
        </Settings>
      </Main>

      <SidePanel.DarkBackdrop close={closePanel} show={panel !== PANELS.none}>
        <SidePanel show={panel === PANELS.platforms} width={SIDE_PANEL_WIDTH}>
          <SidePanel.Header close={closePanel} padding={SIDE_PANEL_HEADER_PADDING}>
            Connected accounts
          </SidePanel.Header>
          <PlatformTargets />
        </SidePanel>

        <SidePanel
          depth={1}
          show={panel === PANELS.platforms && selectedPlatform && selectedPlatformTarget}
          width={SIDE_PANEL_WIDTH}
        >
          <SidePanel.Header
            close={() => {
              SELECTED_PLATFORM_TARGET(null);
              SELECTED_PLATFORM(null);
            }}
            padding={SIDE_PANEL_HEADER_PADDING}
          >
            Manage connections
          </SidePanel.Header>
          {selectedPlatform && <ChildPlatformTargets />}
        </SidePanel>

        <SidePanel show={panel === PANELS.selectAccount} width={SIDE_PANEL_WIDTH}>
          <SidePanel.Header close={closePanel} padding={SIDE_PANEL_HEADER_PADDING}>
            Select a space
          </SidePanel.Header>
          <SelectAccount
            accounts={selectableAccounts}
            currentAccount={currentAccount}
            setShowInvitePanel={setShowInvitePanel}
            showInvitePanel={showInvitePanel}
          />
        </SidePanel>

        <SidePanel depth={1} show={showInvitePanel} width={SIDE_PANEL_WIDTH}>
          <SidePanel.Header
            close={() => setShowInvitePanel(false)}
            padding={SIDE_PANEL_HEADER_PADDING}
          >{`Invite people to ${currentAccount?.name}`}</SidePanel.Header>
          <Invite />
        </SidePanel>
      </SidePanel.DarkBackdrop>
    </Container>
  );
};

export default SideMenu;

const NavItemLink = ({ link, icon, iconSize, label, href }) => (
  <li>
    <NavigationLink to={link || href} reloadDocument={!!href} target={href ? "_blank" : "_self"}>
      <OutlinedIcon name={icon} size={iconSize || "18px"} />
      {label && <Label>{label}</Label>}
    </NavigationLink>
  </li>
);

const SettingsButton = ({ onClick, icon, iconSize, label, className, children }) => (
  <li>
    <NavigationButton className={className} onClick={onClick}>
      <OutlinedIcon name={icon} size={iconSize || "18px"} />
      {label && <Label>{label}</Label>}
      {children}
    </NavigationButton>
  </li>
);
