import { gql } from "@apollo/client";

const GET_USER = gql`
  query getUser {
    user {
      email
      emailAccountConnectivityStatusUpdate
      emailNewLeads
      firstName
      id
      isDisabled
      isNew
      isPending
      isVerified
      lastName
      name
      preferredName
      subscriptionId
    }
  }
`;

const GET_ACCOUNTS = gql`
  query getAccounts {
    accounts {
      id
      name
      role
      inviter
      inviterUserId
      isAcceptedInvite
      isRevokedInvite
      isCurrent @client
    }
  }
`;

const GET_ACCOUNT_BY_ID = gql`
  query getAccountById($accountId: ID) {
    account(accountId: $accountId) {
      id
      name
      phoneNumber
      location
      industry
      website
      timezone
      address
      enableLeadSending
      emailLeads
    }
  }
`;

const GET_EMAIL_LEADS = gql`
  query getEmailLeads($accountId: ID) {
    emailLeads(accountId: $accountId)
  }
`;

const CHECK_SUBSCRIPTION = gql`
  query checkSubscription {
    checkSubscription {
      success
      error
    }
  }
`;

export { GET_USER, GET_ACCOUNTS, GET_ACCOUNT_BY_ID, GET_EMAIL_LEADS, CHECK_SUBSCRIPTION };
