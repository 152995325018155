import { gql } from "@apollo/client";

const READ_IN_APP_NOTIFICATION = gql`
  mutation readInAppNotification($id: ID!) {
    readInAppNotification(id: $id)
  }
`;

const DELETE_IN_APP_NOTIFICATION = gql`
  mutation deleteInAppNotification($id: ID!) {
    deleteInAppNotification(id: $id)
  }
`;

const UPDATE_NOTIFICATION_PREFERENCE = gql`
  mutation updateNotificationPreference($accountId: ID!, $typeMethodId: ID!, $isSubscribed: Boolean!) {
    updateNotificationPreference(accountId: $accountId, typeMethodId: $typeMethodId, isSubscribed: $isSubscribed)
  }
`;

export { READ_IN_APP_NOTIFICATION, DELETE_IN_APP_NOTIFICATION, UPDATE_NOTIFICATION_PREFERENCE };
