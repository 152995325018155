const colors = {
  bg: {
    100: "#FCFCFF",
    200: "#FAFAFF",
    300: "#F7F7FF",
    400: "#F5F5FF",
    500: "#F2F2FF",
  },
  blue: {
    100: "#F7FAFF",
    1000: "#090091",
    1100: "#000E45",
    1200: "#01071D",
    1300: "#01030D",
    200: "#EDF7FF",
    300: "#E0F1FF",
    400: "#D4ECFF",
    500: "#A8D7FE",
    600: "#6CB8FF",
    650: "#668BFF",
    700: "#006BFF",
    800: "#004CFF",
    900: "#0026FF",
  },
  cyan: {
    100: "#E2FFFF",
    1000: "#1F2A2A",
    1100: "#061718",
    1200: "#040909",
    1300: "#020404",
    200: "#D0FFFF",
    300: "#A8FFFF",
    400: "#56FFFF",
    500: "#24E8E8",
    600: "#37C5C5",
    650: "#00A2A3",
    700: "#407F7E",
    800: "#3C6A6A",
    900: "#2F4848",
  },
  facebook: {
    100: "#F9FBFF",
    1000: "#082750",
    200: "#F0F6FE",
    300: "#E7F1FE",
    400: "#DBEAFD",
    500: "#B7D5FB",
    600: "#83B6F8",
    650: "#4491FF",
    700: "#1671E6",
    800: "#1361C3",
    900: "#0D4287",
  },
  gray: {
    0: "#FFFFFF",
    100: "#FAFAFA",
    1000: "#282827",
    1100: "#151515",
    1200: "#080808",
    1300: "#040404",
    200: "#F5F5F5",
    300: "#F0F0F0",
    400: "#E9E9E9",
    500: "#D2D2D2",
    600: "#B3B3B2",
    650: "#929191",
    700: "#757575",
    800: "#535352",
    900: "#3E3D3D",
  },
  green: {
    100: "#EBFFEC",
    1000: "#0F2E09",
    1100: "#0A1807",
    1200: "#040A03",
    1300: "#020402",
    200: "#DCFFE0",
    300: "#C5FDCB",
    400: "#B9F8C0",
    500: "#8FE697",
    600: "#5AC961",
    650: "#28A82D",
    700: "#008800",
    800: "#007400",
    900: "#0E4F04",
  },
  linkedIn: {
    100: "#F5FAFD",
    1000: "#002A47",
    200: "#EFF7FB",
    300: "#E5F2F9",
    400: "#D7ECF6",
    500: "#ADD8EE",
    600: "#73BCE2",
    650: "#1C97EF",
    700: "#057BB8",
    800: "#0067A2",
    900: "#004774",
  },
  mauve: {
    100: "#FFF9FF",
    1000: "#2F2434",
    1100: "#18131B",
    1200: "#0A070B",
    1300: "#040305",
    200: "#FBF4FF",
    300: "#F6EBFF",
    400: "#F2E5FF",
    500: "#DFC8FF",
    600: "#C7A4ED",
    650: "#AD80CC",
    700: "#8F64A8",
    800: "#644873",
    900: "#493653",
  },
  navy: {
    100: "#FCFAFF",
    1000: "#191874",
    1100: "#0A0B46",
    1200: "#030426",
    1300: "#010219",
    200: "#F4F4FF",
    300: "#EEEFFF",
    400: "#E4E7FF",
    500: "#C8D0FF",
    600: "#A0AEFF",
    650: "#7887FF",
    700: "#5B60FF",
    800: "#3E31E0",
    900: "#2B20B1",
  },
  orange: {
    100: "#FFFAEE",
    1000: "#461B0C",
    1100: "#250F07",
    1200: "#100603",
    1300: "#080302",
    200: "#FFF6E2",
    300: "#FFEFCB",
    400: "#FFE6B0",
    500: "#FFC96B",
    600: "#FF9B20",
    650: "#F36600",
    700: "#BB5914",
    800: "#9C4D18",
    900: "#663818",
  },
  purple: {
    100: "#FFF9FF",
    1000: "#301E4C",
    1100: "#2b1b44",
    1200: "#26183d",
    1300: "#221535",
    200: "#FAF2FE",
    300: "#F6ECFE",
    400: "#F2E4FE",
    500: "#E2C8FE",
    600: "#CC9FFE",
    650: "#B56FFF",
    700: "#A239FF",
    800: "#842AEA",
    900: "#562B91",
  },
  red: {
    100: "#FFF8F8",
    1000: "#471915",
    1100: "#280D07",
    1200: "#120504",
    1300: "#080302",
    200: "#FFF3F2",
    300: "#FDEBEA",
    400: "#FDE4E4",
    500: "#FFC4C2",
    600: "#FF9592",
    650: "#FF574D",
    700: "#DC3332",
    800: "#BC2928",
    900: "#7F221E",
  },
  twitter: {
    100: "#F4FAFE",
    1000: "#082A41",
    200: "#EDF7FE",
    300: "#E1F2FD",
    400: "#D5ECFC",
    500: "#A7D8F9",
    600: "#64BBF4",
    650: "#5D94DC",
    700: "#167ABD",
    800: "#1367A0",
    900: "#0D476E",
  },
};

const typography = {
  defaultColor: colors.gray[900],
  fonts: ['"Sofia Pro"', "sans-serif"].join(", "),
  letterSpacing: { regular: "0.1px", wide: "2px" },
  size: {
    l1: "32px",
    l2: "36px",
    l3: "40px",
    l4: "48px",
    m1: "20px",
    m2: "24px",
    m3: "28px",
    s1: "12px",
    s2: "14px",
    s3: "16px",
  },
};

const spacers = {
  desktop: {
    l: "40px",
    m: "32px",
    s: "16px",
    xl: "64px",
    xs: "8px",
    xxl: "96px",
    xxs: "4px",
  },
  mobile: {},
  tablet: {},
};

const grid = {
  desktop: {
    cols: 12,
    gutter: "24px",
    margin: "32px",
    max: "1280px",
    min: "1096px",
  },
  large: {
    cols: 12,
    gutter: "24px",
    margin: "32px",
    max: "1520px",
    min: "1280px",
  },
  mobile: {
    cols: 4,
    gutter: "12px",
    margin: "16px",
    max: "720px",
    min: "375px",
  },
  tablet: {
    cols: 8,
    gutter: "16px",
    margin: "24px",
    max: "1096px",
    min: "720px",
  },
  xl: {
    cols: 12,
    gutter: "24px",
    margin: "32px",
    max: "2152px",
    min: "1520px",
  },
};

const elevation = {
  100: "0px 1px 3px 1px #8B92A21A, 0px 1px 2px 0px #8B92A21A",
  200: "0px 2px 6px 2px #8B92A21A, 0px 1px 2px 0px #8B92A21A",
  300: "0px 1px 3px 0px #8B92A21A, 0px 4px 8px 3px #8B92A21A",
  400: "0px 2px 3px 0px #8B92A21A, 0px 6px 10px 4px #8B92A21A",
  500: "0px 2px 3px 0px #8B92A21A, 0px 8px 12px 6px #8B92A21A",
};

const constants = {
  sideMenuWidth: "89px",
};

export { colors, typography, spacers, grid, elevation, constants };
