import styled from "@emotion/styled";

import { spacers } from "../../styles/theme";

const Spacer = styled.div`
  height: ${(props) => spacers.desktop[props.size]};
  width: ${(props) => spacers.desktop[props.size]};
  ${(props) => props.mt && `margin-top: ${props.mt}`};
  ${(props) => props.mb && `margin-bottom: ${props.mb}`};
`;

export default Spacer;
