import { useApolloClient, useQuery, useReactiveVar } from "@apollo/client";
import { CURRENT_ACCOUNT } from "@gm-graphql/localStates/account";
import { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { FullWindowLoader } from "../components/common/loaders";
import paths from "../config/paths";
import { CHECK_SUBSCRIPTION } from "../graphql/queries/account";
import { GET_ACCOUNT_BY_ID } from "../graphql/queries/user";
import useDeepCompareEffect from "../hooks/use-deep-compare-effect";
import gleap from "../lib/gleap-helper";

const PayingUser = () => {
  const apolloClient = useApolloClient();
  const [retry, setRetry] = useState(false);
  const currentAccount = useReactiveVar(CURRENT_ACCOUNT);
  const { data: getAccount } = useQuery(GET_ACCOUNT_BY_ID, {
    variables: { accountId: currentAccount },
    skip: !currentAccount,
    fetchPolicy: "cache-only",
  });
  const { data, error, loading } = useQuery(CHECK_SUBSCRIPTION, {
    fetchPolicy: "network-only",
    variables: { accountId: currentAccount },
    onCompleted: () => setRetry(false),
    onError: () => setRetry(true),
    skip: !currentAccount,
    pollInterval: retry ? 3000 : 1000 * 60 * 10, // TODO: update the retry interval incrementally each retry attempt
  });

  useDeepCompareEffect(() => {
    if (data?.checkSubscription?.data) {
      gleap.addPlanData({ spaceId: currentAccount, ...data.checkSubscription.data });
    }
  }, [data, currentAccount]);

  useDeepCompareEffect(() => {
    if (getAccount?.account) {
      gleap.addSpaceData(getAccount.account);
    }
  }, [getAccount]);

  if (error) {
    apolloClient.clearStore();
    if (error.graphQLErrors.find((err) => err.extensions.code === "SUBSCRIPTION_INACTIVE")) {
      return <Navigate to={paths.checkout} />;
    }
    return <Navigate to={paths.accounts} />;
  }

  // FIXME: Check for entitlements when we have chargebee in place
  if (!data?.checkSubscription || loading) return <FullWindowLoader />;
  if (!data.checkSubscription.success) return <Navigate to={paths.checkout} />;

  return <Outlet />;
};

export default PayingUser;
