import { cva } from "class-variance-authority";

export const button = cva(
  "inline-flex cursor-pointer items-center gap-1 rounded-full text-s2 font-bold first-letter:uppercase focus:outline-none disabled:cursor-not-allowed",
  {
    variants: {
      type: {
        solidDark:
          "border border-solid border-primary-900 bg-primary-900 text-white hover:border-primary-1000 hover:bg-primary-1000 focus:border-primary-1000 active:border-primary-1000 active:bg-primary-1000 active:hover:border-primary-1000 active:hover:bg-primary-1000 disabled:border-gray-400 disabled:bg-gray-400 disabled:text-gray-600 active:disabled:border-gray-400 active:disabled:bg-gray-400 active:disabled:text-gray-600",
        solid:
          "border border-solid border-primary-700 bg-primary-700 text-white hover:border-primary-800 hover:bg-primary-800 focus:border-primary-900 active:border-primary-900 active:bg-primary-900 active:hover:border-primary-900 active:hover:bg-primary-900 disabled:border-gray-400 disabled:bg-gray-400 disabled:text-gray-600 active:disabled:border-gray-400 active:disabled:bg-gray-400 active:disabled:text-gray-600",
        outline:
          "border border-solid border-primary-700 bg-transparent text-primary-700 hover:bg-primary-100 focus:border-primary-900 active:bg-primary-200 active:text-primary-900 active:hover:bg-primary-200 active:hover:text-primary-900 disabled:border-gray-400 disabled:bg-transparent disabled:text-gray-600 active:disabled:border-gray-400 active:disabled:bg-transparent active:disabled:text-gray-600",
      },
      grayscale: {
        true: "grayscale filter",
      },
      size: {
        large: "px-[24px] py-[15px]",
        medium: "px-[16px] py-[6px]",
        small: "px-[12px] py-[4px]",
      },
      capitalize: {
        true: "capitalize",
      },
    },
    compoundVariants: [
      {
        size: "large",
        rounded: true,
        class: "p-[15px]",
      },
      {
        size: "medium",
        rounded: true,
        class: "p-[8px]",
      },
      {
        size: "small",
        rounded: true,
        class: "p-[5px]",
      },
    ],
    defaultVariants: {
      type: "solid",
      size: "medium",
      rounded: false,
    },
  }
);
