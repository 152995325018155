import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { colors, elevation } from "../../styles/theme";
import { BodyBold, BodySmallBold, MiniBold } from "./typography";

const BaseButton = styled.button`
  cursor: pointer;
  border-radius: 100px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  &::first-letter {
    text-transform: uppercase;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const LargeButton = styled(BodyBold.withComponent("button"))`
  padding: 15px 24px 13px;
`;
const MediumButton = styled(BodySmallBold.withComponent("button"))`
  padding: 8px 16px;
`;
const MediumCircularButton = styled(MediumButton)`
  padding: 8px;
`;

const SmallButton = styled(MiniBold.withComponent("button"))`
  padding: 4px 12px;
`;
const SmallCircularButton = styled(SmallButton)`
  padding: 5px;
`;

const selectedTextCss = (props) => css`
  color: ${colors[props.color][props.shade + 300]};
  background-color: ${colors[props.color][props.shade - 400]};
  box-shadow: none;
`;

const disabledTextCss = (props) => css`
  color: ${colors.gray[props.shade - 100]};
  pointer-events: none;
`;

const TextButton = styled(BaseButton)`
  color: ${(props) => colors[props.color][props.shade]};
  background-color: transparent;

  &:hover {
    color: ${(props) => colors[props.color][props.shade + 100]};
    background-color: ${(props) => colors[props.color][props.shade - 600]};
  }

  &:focus {
    color: ${(props) => colors[props.color][props.shade + 100]};
    background-color: ${(props) => colors[props.color][props.shade - 600]};
    box-shadow: inset 0px 0px 0px 2px ${(props) => colors[props.color][props.shade + 200]};
  }

  &:active:hover {
    color: ${(props) => colors[props.color][props.shade + 200]};
    background-color: ${(props) => colors[props.color][props.shade - 500]};
    box-shadow: none;
  }

  &:disabled {
    ${disabledTextCss}
  }

  ${(props) => props.isDisabled && disabledTextCss}

  ${(props) => props.isSelected && selectedTextCss}
`;

TextButton.defaultProps = { color: "purple", shade: 700 };

const LargeTextButton = LargeButton.withComponent(TextButton);
const MediumTextButton = MediumButton.withComponent(TextButton);
const MediumCircularTextButton = MediumCircularButton.withComponent(TextButton);
const SmallTextButton = SmallButton.withComponent(TextButton);
const SmallCircularTextButton = SmallCircularButton.withComponent(TextButton);

// FIXME: Review DarkButton styles (ML)
const DarkButton = styled(BaseButton)`
  color: ${(props) => props.color};
  background-color: ${(props) => colors[props.backgroundColor][props.backgroundShade]};
  box-shadow: ${elevation[100]};

  &:hover {
    background-color: ${(props) => colors[props.backgroundColor][props.backgroundShade + 100]};
    box-shadow: ${elevation[200]};
  }

  &:focus {
    box-shadow:
      ${elevation[200]},
      inset 0px 0px 0px 2px ${(props) => colors[props.backgroundColor][props.backgroundShade + 200]};
  }

  &:active:hover {
    background-color: ${(props) => colors[props.backgroundColor][props.backgroundShade + 200]};
    box-shadow: ${elevation[100]};
  }

  &:disabled {
    pointer-events: none;
    color: ${(props) => colors.gray[props.backgroundShade - 100]};
    background-color: ${(props) => colors.gray[props.backgroundShade - 300]};
    box-shadow: none;
  }

  &:active:hover {
    color: ${(props) => props.color};
    background-color: ${(props) => colors[props.backgroundColor][props.backgroundShade + 300]};
    box-shadow: none;
  }

  ${(props) =>
    props.isSelected &&
    css`
      color: ${props.color};
      background-color: ${colors[props.backgroundColor][props.backgroundShade + 300]};
      box-shadow: none;
    `}
`;

DarkButton.defaultProps = { backgroundColor: "purple", backgroundShade: 700, color: "white" };

const LargeDarkButton = LargeButton.withComponent(DarkButton);
const MediumDarkButton = MediumButton.withComponent(DarkButton);
const SmallDarkButton = SmallButton.withComponent(DarkButton);

const LightButton = styled(BaseButton)`
  color: ${(props) => colors[props.color][props.shade]};
  background-color: ${(props) => colors[props.backgroundColor][props.backgroundShade]};
  box-shadow: ${elevation[200]};

  &:hover {
    color: ${(props) => colors[props.color][props.shade + 100]};
    background-color: ${(props) => colors[props.backgroundColor][props.backgroundShade + 100]};
    box-shadow: ${elevation[300]};
  }

  &:focus {
    box-shadow:
      ${elevation[200]},
      inset 0px 0px 0px 2px ${(props) => colors[props.color][props.shade + 100]};
  }

  &:active:hover {
    background-color: ${(props) => colors[props.backgroundColor][props.backgroundShade + 200]};
    box-shadow: ${elevation[100]};
  }

  &:disabled {
    pointer-events: none;
    color: ${colors.gray[600]};
    background-color: ${colors.gray[400]};
    box-shadow: none;
  }

  ${(props) =>
    props.isSelected &&
    css`
      color: ${colors[props.backgroundColor][props.backgroundShade]};
      background-color: ${colors[props.color][props.shade + 300]};
      box-shadow: none;
    `}
`;

DarkButton.defaultProps = { backgroundColor: "purple", backgroundShade: 100, color: "purple", shade: 700 };

const LargeLightButton = LargeButton.withComponent(LightButton);
const MediumLightButton = MediumButton.withComponent(LightButton);
const SmallLightButton = SmallButton.withComponent(LightButton);
const SmallCircularLightButton = SmallCircularButton.withComponent(LightButton);
const MediumCircularLightButton = MediumCircularButton.withComponent(LightButton);

const pressedOutlinedCss = (props) => css`
  color: ${colors[props.color][props.shade + 200]};
  background-color: ${colors[props.color][props.shade]}33;
  box-shadow: inset 0px 0px 0px 1px ${colors[props.color][props.shade + 200]};
`;

const OutlinedButton = styled(BaseButton)`
  color: ${(props) => colors[props.color][props.shade]};
  background-color: transparent;
  box-shadow: inset 0px 0px 0px 1px ${(props) => colors[props.color][props.shade]};

  &:hover {
    color: ${(props) => colors[props.color][props.shade + 100]};
    background-color: ${(props) => colors[props.color][props.shade]}1A;
    box-shadow: inset 0px 0px 0px 1px ${(props) => colors[props.color][props.shade + 100]};
  }

  &:focus {
    color: ${(props) => colors[props.color][props.shade + 100]};
    box-shadow:
      ${elevation[200]},
      inset 0px 0px 0px 2px ${(props) => colors[props.color][props.shade + 200]};
  }

  &:active:hover {
    ${pressedOutlinedCss}
  }

  ${(props) => props.isPressed && pressedOutlinedCss}

  &:disabled {
    color: ${(props) => colors.gray[props.shade - 100]};
    box-shadow: inset 0px 0px 0px 1px ${(props) => colors.gray[props.shade - 100]};

    &:hover {
      color: ${(props) => colors.gray[props.shade]};
      background-color: ${colors.gray[100]};
    }
  }

  // FIXME: consult with Doruk
  ${
    (props) => props.isSelected && pressedOutlinedCss
    // css`
    // 	color: white;
    // 	background-color: ${colors[props.color][props.shade + 300]}CC;
    // 	box-shadow: none;
    // `
  }
`;

OutlinedButton.defaultProps = { color: "purple", shade: 700 };

const LargeOutlinedButton = LargeButton.withComponent(OutlinedButton);
const MediumOutlinedButton = MediumButton.withComponent(OutlinedButton);
const SmallOutlinedButton = SmallButton.withComponent(OutlinedButton);

export {
  BaseButton,
  LargeButton,
  MediumButton,
  SmallButton,
  TextButton,
  LargeTextButton,
  MediumTextButton,
  MediumCircularTextButton,
  SmallTextButton,
  SmallCircularTextButton,
  DarkButton,
  LargeDarkButton,
  MediumDarkButton,
  SmallDarkButton,
  LightButton,
  LargeLightButton,
  MediumLightButton,
  MediumCircularLightButton,
  SmallLightButton,
  SmallCircularLightButton,
  OutlinedButton,
  LargeOutlinedButton,
  MediumOutlinedButton,
  SmallOutlinedButton,
};
