import styled from "@emotion/styled";

import { grid } from "../styles/theme";

const Main = styled.main`
  min-height: 100vh;
  display: grid;
  padding: 0 ${grid.desktop.margin};
  grid-template-columns: repeat(${grid.desktop.cols}, 1fr);
  column-gap: ${grid.desktop.gutter};
`;

const Left = styled.div`
  grid-column: auto / span 6;
`;

const Right = styled.div`
  grid-column: auto / span 6;
`;

const TwoPanel = ({ primary, secondary }) => (
  <Main>
    <Left>{primary}</Left>
    <Right>{secondary}</Right>
  </Main>
);

export default TwoPanel;
