import styled from "@emotion/styled";

const Container = styled.div`
  padding: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`;

const CardContent = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const Card = ({ onClick, className, children, ...props }) => (
  <Container className={className ?? ""} onClick={onClick} {...props}>
    {children}
  </Container>
);

Card.Content = CardContent;

export default Card;
