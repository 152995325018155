import Gleap from "gleap";

import { isJsonStringValid } from "../components/ads/wizard/constants";

export const identify = (user) => {
  if (user?.id) {
    Gleap.identify(user.id, {
      email: user.email,
      name: user.name,
      environment: import.meta.env.MODE,
      siteUrl: import.meta.env.VITE_SITE_URL,
    });
  }
};

export const clearIdentity = () => {
  if (!Gleap.isUserIdentified()) return;
  Gleap.clearIdentity();
};

export const addSpaceData = (space) => {
  if (!Gleap.isUserIdentified()) return;
  const identityUser = Gleap.getIdentity();
  let spaces = {};
  if (isJsonStringValid(identityUser?.customData?.spaces)) {
    spaces = JSON.parse(identityUser?.customData?.spaces);
  }
  spaces[space.id] = space.name;
  Gleap.updateContact({ spaces: JSON.stringify(spaces) });
};

export const addPlanData = (plan) => {
  if (!Gleap.isUserIdentified()) return;
  const identityUser = Gleap.getIdentity();
  let plans = {};
  let planValues = {};
  if (isJsonStringValid(identityUser?.customData?.plans)) {
    plans = JSON.parse(identityUser?.customData?.plans);
  }
  if (isJsonStringValid(identityUser?.customData?.planValues)) {
    planValues = JSON.parse(identityUser?.customData?.planValues);
  }
  plans[plan.spaceId] = plan.planName;
  planValues[plan.spaceId] = plan.planValue;
  Gleap.updateContact({ plans: JSON.stringify(plans), planValues: JSON.stringify(planValues) });
  setInitialPlanAndValue(plan.planName, plan.planValue);
};

const setInitialPlanAndValue = (plan, value) => {
  if (!Gleap.isUserIdentified() && !plan && !value) return;
  const gleapContact = Gleap.getIdentity();
  if (gleapContact.plan && gleapContact.value) return;
  Gleap.updateContact({
    plan,
    value,
  });
};

const gleap = {
  identify,
  clearIdentity,
  addSpaceData,
  addPlanData,
  setInitialPlanAndValue,
};

export default gleap;
