import { Global as GlobalStyling, css } from "@emotion/react";
import "material-symbols";

import BlackWoff from "../assets/fonts/SofiaProBlack/font.woff";
import BlackWoff2 from "../assets/fonts/SofiaProBlack/font.woff2";
import BoldWoff from "../assets/fonts/SofiaProBold/font.woff";
import BoldWoff2 from "../assets/fonts/SofiaProBold/font.woff2";
import ExtraLightWoff from "../assets/fonts/SofiaProExtraLight/font.woff";
import ExtraLightWoff2 from "../assets/fonts/SofiaProExtraLight/font.woff2";
import LightWoff from "../assets/fonts/SofiaProLight/font.woff";
import LightWoff2 from "../assets/fonts/SofiaProLight/font.woff2";
import MediumWoff from "../assets/fonts/SofiaProMedium/font.woff";
import MediumWoff2 from "../assets/fonts/SofiaProMedium/font.woff2";
import RegularWoff from "../assets/fonts/SofiaProRegular/font.woff";
import RegularWoff2 from "../assets/fonts/SofiaProRegular/font.woff2";
import SemiBoldWoff from "../assets/fonts/SofiaProSemiBold/font.woff";
import SemiBoldWoff2 from "../assets/fonts/SofiaProSemiBold/font.woff2";
import UltraLightWoff from "../assets/fonts/SofiaProUltraLight/font.woff";
import UltraLightWoff2 from "../assets/fonts/SofiaProUltraLight/font.woff2";
import reset from "./reset";
import { typography } from "./theme";

const Global = () => (
  <GlobalStyling
    styles={css`
      @font-face {
        font-family: "Sofia Pro";
        font-style: "normal";
        font-weight: 100;
        src:
          url("${UltraLightWoff2}") format("woff2"),
          url("${UltraLightWoff}") format("woff");
      }

      @font-face {
        font-family: "Sofia Pro";
        font-style: "normal";
        font-weight: 200;
        src:
          url("${ExtraLightWoff2}") format("woff2"),
          url("${ExtraLightWoff}") format("woff");
      }

      @font-face {
        font-family: "Sofia Pro";
        font-style: "normal";
        font-weight: 300;
        src:
          url("${LightWoff2}") format("woff2"),
          url("${LightWoff}") format("woff");
      }

      @font-face {
        font-family: "Sofia Pro";
        font-style: "normal";
        font-weight: 400;
        src:
          url("${RegularWoff2}") format("woff2"),
          url("${RegularWoff}") format("woff");
      }

      @font-face {
        font-family: "Sofia Pro";
        font-style: "normal";
        font-weight: 500;
        src:
          url("${MediumWoff2}") format("woff2"),
          url("${MediumWoff}") format("woff");
      }

      @font-face {
        font-family: "Sofia Pro";
        font-style: "normal";
        font-weight: 600;
        src:
          url("${SemiBoldWoff2}") format("woff2"),
          url("${SemiBoldWoff}") format("woff");
      }

      @font-face {
        font-family: "Sofia Pro";
        font-style: "normal";
        font-weight: 700;
        src:
          url("${BoldWoff2}") format("woff2"),
          url("${BoldWoff}") format("woff");
      }

      @font-face {
        font-family: "Sofia Pro";
        font-style: "normal";
        font-weight: 800 900;
        src:
          url("${BlackWoff2}") format("woff2"),
          url("${BlackWoff}") format("woff");
      }

      ${reset}

      * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      body {
        height: 100%;
        color: ${typography.defaultColor};
        font-family: ${typography.fonts};
        font-weight: 400;
        letter-spacing: ${typography.letterSpacing.regular};
      }
    `}
  />
);

export default Global;
