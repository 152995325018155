import { cx } from "class-variance-authority";

import { button } from "../../../styles/variations/buttons";

/**
 * @typedef {React.FunctionComponent<React.ButtonHTMLAttributes<HTMLButtonElement> & { rounded: Boolean, type: String, size: String } & React.ClassAttributes<HTMLButtonElement>>} Button
 *
 * @function Button
 * @description A solid button component
 * @type {Button}
 */
const Button = (props) => {
  const { type, size, children, className, rounded, as, ...rest } = props;

  switch (as) {
    case "div":
      return (
        <div className={cx(button({ ...props }), className)} {...rest}>
          {children}
        </div>
      );
  }
  return (
    <button className={cx(button({ ...props }), className)} {...rest}>
      {children}
    </button>
  );
};

export default Button;
