import LoadingSpin from "react-loading-spin";

import { colors } from "../../styles/theme";

export const FullWindowLoader = () => {
  return (
    <div className="fixed flex h-screen w-screen items-center justify-center">
      <LoadingSpin primaryColor={colors.purple[1000]} size={50} width={3} />
    </div>
  );
};

export const PageLoader = () => {
  return (
    <div className="fixed flex size-full items-center justify-center">
      <LoadingSpin primaryColor={colors.purple[1000]} size={50} width={3} />
    </div>
  );
};

export const ContainerLoader = () => {
  return (
    <div className="absolute inset-0 flex size-full items-center justify-center">
      <LoadingSpin primaryColor={colors.purple[1000]} size={50} width={3} />
    </div>
  );
};
