import _ from "lodash";
import { useEffect, useRef } from "react";

const deepCompareEquals = (a, b) => {
  _.isEqual(a, b);
};

function useDeepCompareMemoize(value) {
  const ref = useRef();
  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
}

function useDeepCompareEffect(callback, dependencies) {
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

export default useDeepCompareEffect;
