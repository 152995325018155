import { gql } from "@apollo/client";

import { CURRENT_ACCOUNT } from "./localStates/account";

export const typeDefs = gql`
  extend type Account {
    isCurrent: Boolean!
  }
  extend type Query {
    currentAccount: ID!
  }
`;

export default {
  Account: {
    fields: {
      isCurrent: {
        read(_, { readField }) {
          return CURRENT_ACCOUNT() === readField("id");
        },
      },
    },
  },
  notificationMethod: {
    keyFields: ["id", "typeMethodId"],
  },
  inAppNotifications: {
    keyFields: ["id", "accountId"],
  },
  accountUser: {
    keyFields: ["accountId", "userId", "email"],
  },
  platformTarget: {
    keyFields: ["id", "accountId", "connectedBy", "externalId"],
  },
  CrmTabField: {
    keyFields: ["tabId", "fieldId", "sortOrder"],
  },
};
