import styled from "@emotion/styled";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";

import SideMenu from "../components/account/side-menu";
import { PageLoader } from "../components/common/loaders";
import Notification from "../components/notification/notification";
import { colors, constants, grid } from "../styles/theme";

const Main = styled.main`
  min-height: 100vh;
  background-color: ${colors.bg[500]}33;
  display: grid;
  padding-right: ${grid.desktop.margin};
  grid-template-columns: ${constants.sideMenuWidth} repeat(${grid.desktop.cols}, 1fr);
  column-gap: ${grid.desktop.gutter};
  position: relative;
`;

const Account = () => {
  return (
    <Main>
      <SideMenu />
      <Suspense fallback={<PageLoader />}>
        <Notification />
        <Outlet />
      </Suspense>
    </Main>
  );
};

export default Account;
