import { makeVar } from "@apollo/client";

export const SELECTED_PLATFORM = makeVar(null);
export const SELECTED_PLATFORM_TARGET = makeVar(null);
export const PLATFORM_TABS = makeVar([]);
export const PLATFORM_CHILD_TARGETS_BY_PLATFORM = makeVar(new Map());

export const resetPlatformReactiveVars = () => {
  SELECTED_PLATFORM(null);
  SELECTED_PLATFORM_TARGET(null);
  PLATFORM_TABS([]);
  PLATFORM_CHILD_TARGETS_BY_PLATFORM(new Map());
};
