import { useApolloClient, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { Combobox, Transition } from "@headlessui/react";
import { cx } from "class-variance-authority";
import { DateTime } from "luxon";
import { Fragment, useEffect, useMemo, useState } from "react";

import { CURRENT_ACCOUNT, SHOW_NOTIFICATION } from "../../graphql/localStates/account";
import { DELETE_IN_APP_NOTIFICATION, READ_IN_APP_NOTIFICATION } from "../../graphql/mutations/notification";
import { GET_IN_APP_NOTIFICATIONS } from "../../graphql/queries/notification";
import { NOTIFICATION } from "../../graphql/subscriptions/notification";
import useOutsideClick from "../../hooks/use-outside-click";
import useSubscriptionWithContext from "../../hooks/useSubscriptionWithContext";
import { button } from "../../styles/variations/buttons";
import CustomIcon from "../common/custom-icon";
import { OutlinedIcon } from "../common/icons";
import SidePanel from "../common/side-panel";
import Button from "../common/tw-components/buttons";

export default function Notification() {
  const showNotification = useReactiveVar(SHOW_NOTIFICATION);
  const [ref, show] = useOutsideClick(showNotification, () => SHOW_NOTIFICATION(false));

  return (
    <SidePanel align="right" show={show} ref={ref}>
      <SidePanel.Header close={() => SHOW_NOTIFICATION(false)} padding="24px">
        Notifications
      </SidePanel.Header>
      <Notifications />
    </SidePanel>
  );
}

function Notifications() {
  const currentAccount = useReactiveVar(CURRENT_ACCOUNT);
  const [readFilter, setReadFilter] = useState(null);
  const { data: getNotifications } = useQuery(GET_IN_APP_NOTIFICATIONS, {
    variables: { accountId: currentAccount },
  });

  const notifications = useMemo(() => {
    if (!getNotifications?.inAppNotifications) return [];
    return getNotifications.inAppNotifications.filter((notification) => {
      if (readFilter === "all") return true;
      if (readFilter === "read") return notification.isRead;
      if (readFilter === "unread") return !notification.isRead;
      return true;
    });
  }, [getNotifications?.inAppNotifications, readFilter]);

  return (
    <div className="flex h-full flex-1 flex-col">
      <div className="flex justify-end px-8">
        <ReadStatusDropdown onChange={setReadFilter} />
      </div>
      <div className=" mt-6 flex flex-1 flex-col divide-y divide-solid divide-gray-400 overflow-auto border-y border-solid border-gray-400">
        {notifications.map((notification) => (
          <NotificationItem key={notification.id} {...notification} />
        ))}
      </div>
    </div>
  );
}

function NotificationItem({ id, isRead, payload, createdAt }) {
  const { title, description, link, icon } = payload;
  const date = DateTime.fromMillis(+createdAt);

  const [readNotificationMutation, { loading: readLoading }] = useMutation(READ_IN_APP_NOTIFICATION, {
    awaitRefetchQueries: true,
    refetchQueries: [GET_IN_APP_NOTIFICATIONS],
  });

  const [deleteNotificationMutation, { loading: deleteLoading }] = useMutation(DELETE_IN_APP_NOTIFICATION, {
    awaitRefetchQueries: true,
    refetchQueries: [GET_IN_APP_NOTIFICATIONS],
  });

  const readNotification = () => {
    if (isRead) return;
    readNotificationMutation({ variables: { id } });
  };

  const deleteNotification = () => {
    deleteNotificationMutation({ variables: { id } });
  };

  return (
    <div
      className={`flex cursor-pointer items-center p-4 text-start ${
        !isRead ? "bg-purple-50" : ""
      } hover:bg-purple-100 disabled:grayscale`}
      onClick={readNotification}
      disabled={readLoading || deleteLoading}
    >
      <div className="flex flex-1 flex-col">
        <div className="flex gap-2">
          {icon && (
            <div>
              <CustomIcon name={icon} color="gray" shade={700} size="24px" />
            </div>
          )}
          <div>
            <div className="body-small-bold">{title}</div>
            <div className="mini-gray">{date.diffNow("days").days <= -7 ? date.toISODate() : date.toRelative()}</div>
          </div>
        </div>
        <div className="mini">{description}</div>
      </div>
      <button className="ml-auto shrink-0" onClick={deleteNotification}>
        <OutlinedIcon name="close" color="gray" shade={700} size="18px" />
      </button>
    </div>
  );
}

export function NotificationButton() {
  const client = useApolloClient();
  const currentAccount = useReactiveVar(CURRENT_ACCOUNT);
  const showNotification = useReactiveVar(SHOW_NOTIFICATION);
  const [newNotification, setNewNotification] = useState(false);

  const { data: getNotifications } = useQuery(GET_IN_APP_NOTIFICATIONS, {
    variables: { accountId: currentAccount },
  });

  const unreadNotifications =
    getNotifications?.inAppNotifications?.filter((notification) => !notification.isRead) || [];

  useSubscriptionWithContext(NOTIFICATION, {
    shouldResubscribe: true,
    fetchPolicy: "network-only",
    onData: async (data) => {
      setNewNotification(true);
      // TODO: implement optimistic cache update for notifications
      await client.refetchQueries({
        include: [GET_IN_APP_NOTIFICATIONS],
      });
    },
  });
  const toggleNotification = () => {
    setNewNotification(false);
    SHOW_NOTIFICATION(!showNotification);
  };

  return (
    <Button onClick={toggleNotification} className="relative">
      {newNotification && <div className="absolute -top-1 right-1 size-2 rounded-full bg-red-700" />}
      <OutlinedIcon name="notifications_none" size={"18px"} /> {unreadNotifications.length}
    </Button>
  );
}

export function ReadStatusDropdown({ onChange }) {
  const [selected, setSelected] = useState("all");

  const options = ["all", "read", "unread"];

  useEffect(() => {
    onChange?.(selected);
  }, [selected]);

  return (
    <div className="relative w-max">
      <Combobox value={selected} onChange={setSelected}>
        <Combobox.Button className={cx(button({ type: "outline", size: "medium", grayscale: true }), "capitalize")}>
          {selected || "all"}
          <OutlinedIcon name="arrow_drop_down" size="18px" />
        </Combobox.Button>
        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Combobox.Options className="absolute right-0  z-10 mt-1 max-h-80 w-max max-w-72 divide-y divide-solid divide-gray-500 overflow-auto rounded-lg border border-solid border-gray-500 bg-white shadow-200">
            <>
              {options.map((option) => (
                <Combobox.Option
                  key={option}
                  className={({ selected }) =>
                    `body-small-bold px-4 py-2 capitalize ${selected ? "bg-purple-500 text-white" : ""}`
                  }
                  value={option}
                >
                  {option}
                </Combobox.Option>
              ))}
            </>
          </Combobox.Options>
        </Transition>
      </Combobox>
    </div>
  );
}
