import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";

import { CURRENT_ACCOUNT } from "../../graphql/localStates/account";
import { INVITE_USER } from "../../graphql/mutations/account";
import { GET_ACCOUNT_USERS, GET_ACCOUNT_USER_ROLES } from "../../graphql/queries/account";
import { GET_USER } from "../../graphql/queries/user";
import { colors } from "../../styles/theme";
import { MediumDarkButton } from "../common/buttons";
import { RemovableChip } from "../common/chips";
import { Dropdown, TextInput } from "../common/forms";
import { OutlinedIcon } from "../common/icons";
import { CaptionBold, Overline } from "../common/typography";

const Content = styled.div`
  padding: 8px 16px 16px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  scrollbar-width: none; /* Firefox */
  flex: 1 1 auto;

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const EmailInputDiv = styled(TextInput.withComponent("div"))`
  margin-bottom: 24px;
  padding: 7px 16px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 4px;

  &:focus-within {
    outline: none;
    box-shadow: 0px 0px 0px 2px ${colors.purple[900]} inset;
  }
`;

const EmailInput = styled.input`
  height: 26px;
  flex: 1 1 auto;
  margin: 0;
  outline: none;

  &:not(:focus) {
    width: 0px;
  }
`;

const Label = styled(CaptionBold.withComponent("label"))`
  margin-bottom: 2px;
  color: ${colors.gray[700]};
`;

const RoleDropdown = styled(Dropdown)`
  text-transform: capitalize;
`;

const InviteButton = styled(MediumDarkButton)`
  margin-block: 24px 16px;
  align-self: flex-end;
`;

const SectionHeading = styled(Overline)`
  margin: 0px 0 8px;
`;

const Invite = () => {
  const inputRef = useRef(null);

  const [currentAccountRole, setCurrentAccountRole] = useState(null);

  const [emails, setEmails] = useState([]);
  const [email, setEmail] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [hasInvited, setHasInvited] = useState(false);

  const currentAccount = useReactiveVar(CURRENT_ACCOUNT);
  const { data: currentUser } = useQuery(GET_USER, { fetchPolicy: "cache-only" });

  const { data: accountUserRoles } = useQuery(GET_ACCOUNT_USER_ROLES, {
    variables: { accountId: currentAccount },
    onCompleted: (data) => setSelectedRole(data.accountUserRoles[0].name),
  });

  const { data: getAccountUsers } = useQuery(GET_ACCOUNT_USERS, {
    variables: { accountId: currentAccount },
    onCompleted: (data) => {},
  });

  const [inviteUser, { loading }] = useMutation(INVITE_USER, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_ACCOUNT_USERS, variables: { accountId: currentAccount } }],
  });

  const addEmail = () => {
    if (email.length > 0 && !emails.includes(email)) {
      setEmails((prev) => [...prev, email]);
      setEmail("");
    }
  };

  const remove = (emailToRemove) => {
    const filtered = emails.filter((email) => email !== emailToRemove);
    setEmails(filtered);
  };

  const onInputKeyDown = (e) => {
    if (e.key === "Backspace" && email.length === 0 && emails.length > 0) {
      remove(emails.at(-1));
    } else if (e.key === "Enter") {
      addEmail();
    }
  };

  const invite = async () => {
    const _emails = emails;
    const roleId = parseInt(accountUserRoles?.accountUserRoles?.find((role) => role.name === selectedRole)?.id);
    setEmail("");
    setEmails([]);
    await inviteUser({
      variables: {
        data: {
          accountId: currentAccount,
          emails: _emails,
          roleId,
        },
      },
    });
    setHasInvited(true);
    setTimeout(() => {
      setHasInvited(false);
    }, 800);
  };

  useEffect(() => {
    if (currentAccount && currentUser && getAccountUsers) {
      const user = getAccountUsers?.accountUsers?.find((user) => user.userId === currentUser.user.id);
      setCurrentAccountRole(user.roleId);
    }
  }, [currentAccount, currentUser, getAccountUsers]);

  const roles = accountUserRoles?.accountUserRoles?.map((role) => role.name) || [];

  return (
    <Content>
      {[1, 2].includes(currentAccountRole) && (
        <>
          <Label htmlFor="email">Invite</Label>
          <EmailInputDiv onClick={() => inputRef.current.focus()}>
            {emails?.length > 0 &&
              emails.map((email, index) => (
                <RemovableChip key={`${email}-${index}`} remove={() => remove(email)}>
                  <OutlinedIcon name="email" />
                  {email}
                </RemovableChip>
              ))}

            <EmailInput
              id="email"
              onBlur={addEmail}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={onInputKeyDown}
              ref={inputRef}
              type="email"
              value={email}
            />
          </EmailInputDiv>
          <Label htmlFor="role">Invite as</Label>
          <RoleDropdown id="role" items={roles} selectedItem={selectedRole} setSelectedItem={setSelectedRole} />
          <InviteButton disabled={loading || emails.length === 0} onClick={invite}>
            {hasInvited ? "Invited!" : loading ? "Inviting" : "Invite"}
          </InviteButton>
        </>
      )}
      <div className="mb-4">
        <SectionHeading>Account Members</SectionHeading>
        <div className="flex flex-col gap-2">
          {getAccountUsers?.accountUsers?.length > 0 && (
            <>
              {getAccountUsers?.accountUsers.map((user) => (
                <UserChip key={user.email} user={user} />
              ))}
            </>
          )}
        </div>
      </div>
    </Content>
  );
};

const UserChip = ({ user }) => {
  const notAccepted = !user.isAcceptedInvite && user.inviterUserId;
  return (
    <RemovableChip
      disabled={true} // enable when access control is implemented
      className="relative !bg-gray-200 !text-gray-900"
      remove={() => console.log("remove")}
    >
      <div className="flex w-full items-center gap-1">
        <OutlinedIcon name="person" size="20px" className="shrink-0" />
        {!notAccepted ? (
          <div
            className="flex cursor-pointer items-center justify-center gap-1 rounded-full bg-green-200 px-0.5"
            data-tooltip-content="Invite accepted"
            data-tooltip-id="tooltip"
          >
            <OutlinedIcon name="check" color="green" shade={700} size="20px" className="shrink-0" />
          </div>
        ) : (
          <div
            className="just flex cursor-pointer items-center gap-1 rounded-full bg-orange-200 px-0.5"
            data-tooltip-content="Invite pending"
            data-tooltip-id="tooltip"
          >
            <OutlinedIcon name="schedule" color="orange" shade={700} size="20px" className="shrink-0" />
          </div>
        )}
        <span
          className="flex flex-1 flex-col truncate pl-1"
          data-tooltip-id="tooltip"
          data-tooltip-content={user.email + " [" + user.role + "]"}
        >
          <span className="font-bold">{user.name}</span>
          <span className="font-normal text-gray-700"> {user.email}</span>
        </span>
      </div>
    </RemovableChip>
  );
};

export default Invite;
