import { gql } from "@apollo/client";

const NOTIFICATION = gql`
  subscription notification($context: subscriptionContext!) {
    notification(context: $context) {
      id
      accountId
      payload
      createdAt
      isRead
    }
  }
`;

export { NOTIFICATION };
