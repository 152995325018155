import { gql } from "@apollo/client";

const PLATFORM_TARGET_CONNECT_STATUS = gql`
  subscription platformTargetConnectStatus($context: subscriptionContext!) {
    platformTargetConnectStatus(context: $context) {
      id
      isDeauthorized
      isDisconnected
    }
  }
`;

export { PLATFORM_TARGET_CONNECT_STATUS };
