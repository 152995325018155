import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { colors, elevation } from "../../styles/theme";
import { BaseButton, MediumOutlinedButton } from "./buttons";
import { OutlinedIcon } from "./icons";
import { BodySmallBold } from "./typography";

const MenuContainer = styled.div`
  box-shadow: ${elevation[100]};
  border-radius: 8px;
  display: grid;
  grid-gap: 1px;
`;

const Menu = ({ className, children }) => {
  return <MenuContainer className={className ?? ""}>{children}</MenuContainer>;
};

const DropdownButton = styled(MediumOutlinedButton)`
  padding-block: 7px;
  text-transform: capitalize;
`;

const DropdownIcon = styled(OutlinedIcon)`
  position: relative;
  top: 1px;
`;

Menu.Toggle = ({ onClick, color, shade, className, children }) => (
  <DropdownButton className={className ?? ""} color={color ?? "gray"} onClick={onClick} shade={shade ?? 700}>
    {children}
    <DropdownIcon name="arrow_drop_down" size="18px" />
  </DropdownButton>
);

const itemCss = (props) => css`
  padding: 11px 15px;
  outline: solid ${colors.gray[500]} 1px;
  border-radius: 0px;
  color: ${colors.gray[900]};
  white-space: nowrap;
  justify-content: space-between;

  ${!props.isSingle &&
  css`
    &:first-child {
      border-radius: 8px 8px 0px 0px;
    }

    &:last-child {
      border-radius: 0px 0px 8px 8px;
    }
  `}
`;

const divBackgroundColorCss = (props) => css`
  background-color: ${colors[props.backgroundColor][props.shade]};

  ${props.isSelected &&
  css`
    background-color: ${colors[props.backgroundColor][props.shade + 200]};

    ${BodySmallBold} {
      color: ${colors[props.backgroundColor][props.shade + 900]};
    }
  `}
`;

Menu.ItemDiv = styled(BodySmallBold.withComponent("div"))`
  ${itemCss}
  display: inline-flex;
  align-items: center;

  ${(props) => props.backgroundColor && props.shade && divBackgroundColorCss(props)}
`;

const buttonWhiteBackgroundCss = (props) => css`
  &:hover {
    background-color: ${colors.gray[100]};
  }

  &:active {
    background-color: ${colors.gray[200]};
  }
`;

const buttonBackgroundColorCss = (props) => css`
  background-color: ${colors[props.backgroundColor][props.shade]};

  &:hover {
    background-color: ${colors[props.backgroundColor][props.shade + 100]};
  }

  &:active {
    background-color: ${colors[props.backgroundColor][props.shade + 200]};
  }
`;

Menu.Item = styled(BodySmallBold.withComponent(BaseButton))`
  ${itemCss}

  ${(props) =>
    props.backgroundColor && props.shade ? buttonBackgroundColorCss(props) : buttonWhiteBackgroundCss(props)}

	&:focus {
    position: relative;
    outline: solid ${colors.gray[600]} 1px;
  }

  &:disabled {
    background-color: ${colors.gray[100]};
    color: ${colors.gray[500]};
  }

  ${(props) => props.isSingle && `border-radius: 8px;`}
`;

export default Menu;
