import { gql } from "@apollo/client";

export const GET_PLATFORM_TARGETS = gql`
  query getPlatformTargets($accountId: ID!, $filters: platformTargetFilters) {
    platformTargets(accountId: $accountId, filters: $filters) {
      id
      connectedBy
      parentId
      accountId
      externalId
      platformTargetTypeId
      platformTargetType
      platformId
      platform
      name
      email
      currency
      isEnabled
      isEnabledAtLeastOnce
      isReconnectionRequired
      isDisconnected
      isDeauthorized
    }
  }
`;

export const GET_PLATFORMS = gql`
  query getPlatforms {
    platforms {
      id
      name
    }
  }
`;

export const GET_PLATFORM_PROFILE = gql`
  query getPlatformProfile($id: ID!) {
    platformProfile(id: $id) {
      url
      name
    }
  }
`;
