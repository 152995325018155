import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";

import backgroundLight from "../assets/images/auth-background-light.svg";

const Main = styled.main`
  background-image: url(${backgroundLight});
  background-size: cover;
`;

const Onboarding = () => {
  return (
    <Main>
      <Outlet />
    </Main>
  );
};

export default Onboarding;
