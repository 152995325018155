import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import styled from "@emotion/styled";
import { OutlinedIcon } from "@gm-components/common/icons";
import _ from "lodash";
import { useEffect, useState } from "react";

import { CURRENT_ACCOUNT } from "../../graphql/localStates/account";
import {
  PLATFORM_CHILD_TARGETS_BY_PLATFORM,
  SELECTED_PLATFORM,
  SELECTED_PLATFORM_TARGET,
} from "../../graphql/localStates/platform";
import { TOGGLE_PLATFORM_TARGET } from "../../graphql/mutations/platform";
import { GET_AD_METRICS_DATA } from "../../graphql/queries/metrics";
import { GET_PLATFORM_TARGETS } from "../../graphql/queries/platform";
import useDeepCompareEffect from "../../hooks/use-deep-compare-effect";
import { colors } from "../../styles/theme";
import { Tab, Tabs } from "../common/tabs";
import Toggle from "../common/toggle";
import { Body, Mini } from "../common/typography";

const Content = styled.div`
  padding: 24px 16px 16px;
  overflow: auto;
  display: grid;
  grid-auto-rows: 1fr;
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const Capitalize = styled.span`
  text-transform: capitalize;
`;

const ChildTargets = () => {
  const selectedPlatform = useReactiveVar(SELECTED_PLATFORM);
  const selectedPlatformTarget = useReactiveVar(SELECTED_PLATFORM_TARGET);
  const targets = useReactiveVar(PLATFORM_CHILD_TARGETS_BY_PLATFORM);
  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(null);

  useDeepCompareEffect(() => {
    if (targets.has(selectedPlatform) && !targets.get(selectedPlatform).has(selectedTab)) {
      const sortedTabs = [...targets.get(selectedPlatform).keys()].sort((a, b) => b.localeCompare(a));
      setSelectedTab(sortedTabs?.[0] || null);
      setTabs(sortedTabs || []);
    }
  }, [selectedPlatform, targets]);

  return (
    <>
      <Tabs>
        {tabs.map((name) => (
          <Tab key={name} onClick={() => setSelectedTab(name)} selected={selectedTab === name} stretch>
            {_.upperFirst(_.lowerCase(name))}
          </Tab>
        ))}
      </Tabs>
      <Content>
        {targets.has(selectedPlatform) &&
        targets.get(selectedPlatform).has(selectedTab) &&
        targets.get(selectedPlatform).get(selectedTab).length > 0 ? (
          targets
            .get(selectedPlatform)
            .get(selectedTab)
            .map((target) => {
              return target.parentId === selectedPlatformTarget && <Target key={target.id} target={target} />;
            })
        ) : (
          <Body>
            You don&apos;t have any{" "}
            <Capitalize>
              {selectedPlatform} {selectedTab}{" "}
            </Capitalize>
            child accounts.
          </Body>
        )}
      </Content>
    </>
  );
};

export default ChildTargets;

const TargetContainer = styled.div`
  padding: 11px 0px 11px 8px;
  border-top: solid 1px ${colors.gray[300]};
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:last-of-type {
    border-bottom: solid 1px ${colors.gray[300]};
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${Mini} {
    color: ${colors.gray[700]};
  }
`;

const TargetTitle = styled(Body.withComponent("div"))`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Target = ({ target }) => {
  const currentAccountId = useReactiveVar(CURRENT_ACCOUNT);
  const { data: getPlatformTargets } = useQuery(GET_PLATFORM_TARGETS, {
    variables: { accountId: currentAccountId },
  });

  const [togglePlatformTarget, { loading }] = useMutation(TOGGLE_PLATFORM_TARGET, {
    refetchQueries: [GET_PLATFORM_TARGETS, GET_AD_METRICS_DATA],
  });

  const onToggle = () => {
    if (target.parentId) {
      const parentTarget = getPlatformTargets?.platformTargets?.find(
        (platformTarget) => platformTarget.id === target.parentId
      );
      if (!parentTarget?.isEnabled) return;
    }
    togglePlatformTarget({ variables: { id: target.id, isEnabled: !target.isEnabled } });
  };

  return (
    <TargetContainer>
      <TextContainer>
        <TargetTitle>
          {target.isDeauthorized && (
            <OutlinedIcon
              className="cursor-help"
              data-tooltip-id="tooltip"
              data-tooltip-content="Your Facebook integration has expired. Please reconnect now to avoid any missing data."
              name="sync_problem"
              color="orange"
              shade={700}
              size="24px"
            />
          )}
          {target.name}
        </TargetTitle>
        <Mini>{target.externalId || null}</Mini>
      </TextContainer>
      <Toggle checked={target.isEnabled} disabled={loading} id={target.id} onChange={onToggle} />
    </TargetContainer>
  );
};
