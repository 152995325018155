import styled from "@emotion/styled";

import { colors } from "../../styles/theme";
import { BodySmallBold } from "./typography";

export const Container = styled.div`
  display: flex;
  align-items: center;

  &:before,
  :after {
    content: "";
    width: 100%;
    border-bottom: ${colors.gray[300]} solid 1px;
    flex: 1 1 auto;
  }
`;

const Text = styled(BodySmallBold)`
  margin: 0 16px;
  color: ${colors.gray[700]};
  text-transform: uppercase;
`;

export const DividerWithText = ({ className, children }) => (
  <Container className={className}>
    <Text>{children}</Text>
  </Container>
);

export const Hr = styled.hr`
  border-top: 1px solid ${colors.gray[500]};
`;
