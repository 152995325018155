import { makeVar } from "@apollo/client";
import { isString } from "lodash";

const getCleanValueForStorage = (value) => {
  return isString(value) ? value : JSON.stringify(value);
};

const makeVarPersisted = (initialValue, storageName) => {
  let value = initialValue;

  const previousValue = localStorage.getItem(storageName);
  if (previousValue !== null) {
    try {
      const parsed = JSON.parse(previousValue);
      value = parsed;
    } catch {
      value = previousValue;
    }
  }

  const rv = makeVar(value);

  const onNextChange = (newValue) => {
    try {
      if (newValue === undefined) {
        localStorage.removeItem(storageName);
      } else {
        localStorage.setItem(storageName, getCleanValueForStorage(newValue));
      }
    } catch {}

    rv.onNextChange(onNextChange);
  };
  rv.onNextChange(onNextChange);

  return rv;
};

export default makeVarPersisted;
