import styled from "@emotion/styled";
import { uniqueId } from "lodash";
import { useEffect, useRef, useState } from "react";

import useOutsideClick from "../../hooks/use-outside-click";
import convertComponent from "../../lib/convert-component";
import { colors } from "../../styles/theme";
import Menu from "../common/menus";
import { OutlinedIcon } from "./icons";
import Toggle from "./toggle";
import { Body, BodySmall, CaptionBold, Mini } from "./typography";

const TextLabel = styled(Mini.withComponent("label"))`
  color: ${colors.gray[700]};
`;

const LargeTextLabel = styled(CaptionBold.withComponent("label"))`
  color: ${colors.gray[700]};
`;

const TextInput = styled(Body.withComponent("input"))`
  padding: 10px 16px;
  color: ${colors.gray[900]};
  box-shadow: 0px 0px 0px 1px ${colors.gray[500]} inset;

  &::placeholder {
    color: ${colors.gray[600]};
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 2px ${colors.purple[900]} inset;
  }
`;

const SmallTextInput = styled(BodySmall.withComponent("input"))`
  padding: 10px 16px;
  color: ${colors.gray[900]};
  box-shadow: 0px 0px 0px 1px ${colors.gray[500]} inset;

  &::placeholder {
    color: ${colors.gray[600]};
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 2px ${colors.purple[900]} inset;
  }
`;

const TextArea = styled(TextInput.withComponent("textarea"))``;

const DropdownContainer = styled.div`
  width: 100%;
  position: relative;
`;

const PasswordContainer = styled.div`
  position: relative;
  width: 100%;

  & > input {
    width: 100%;
    padding-right: 40px;
  }

  & > i {
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    user-select: none;
  }
`;

const PasswordInput = ({ ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <PasswordContainer>
      <TextInput {...props} type={showPassword ? "text" : "password"} />
      <OutlinedIcon
        name={showPassword ? "visibility" : "visibility_off"}
        onClick={() => setShowPassword((prev) => !prev)}
        color="gray"
        shade={700}
      />
    </PasswordContainer>
  );
};

const DropdownElement = styled(Body.withComponent("button"))`
  cursor: pointer;
  width: calc(100% - 2px);
  margin-top: 1px;
  padding: 9px 15px;
  outline: solid ${colors.gray[500]} 1px;
  display: flex;
  align-items: center;

  &:focus {
    outline: solid 2px ${colors.purple[900]};
    position: relative;
    z-index: 1;
  }
`;

const DropdownHeader = styled(DropdownElement)`
  justify-content: space-between;
`;

const DropdownList = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  width: 100%;
  background-color: white;
  display: grid;
  grid-auto-rows: 1fr;
  z-index: 50;
`;

const DropdownItem = styled(DropdownElement)`
  margin-bottom: 0px;
  text-align: left;
  gap: 8px;

  ${(props) => !props.isSelected && "padding-left: 47px;"}
`;

const Dropdown = ({ items, selectedItem, setSelectedItem, className }) => {
  const [showList, setShowList] = useState(false);

  const containerRef = useRef(null);

  useEffect(() => {
    const callback = (e) => {
      if (!containerRef.current?.contains(e.target)) {
        setShowList(false);
      }
    };
    window.addEventListener("click", callback);
    return () => window.removeEventListener("click", callback);
  }, []);

  return (
    <DropdownContainer onClick={() => setShowList((prev) => !prev)} ref={containerRef}>
      <DropdownHeader className={className}>
        {selectedItem}
        <OutlinedIcon name="arrow_drop_down" size="18px" className="ml-auto" />
      </DropdownHeader>
      {showList && (
        <DropdownList>
          {items.map((item) => (
            <DropdownItem
              className={className}
              isSelected={item === selectedItem}
              key={item}
              onClick={() => setSelectedItem(item)}
            >
              {item === selectedItem && <OutlinedIcon name="check" />}
              {item}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

const FilterDropdownContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DropdownItemContainer = styled(Menu)`
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 6px;
  background-color: white;
  z-index: 2;
  min-width: 100%;
  max-height: 400px;
  overflow-y: auto;
  /* padding: 1px;
  margin: -1px; */
  border: 1px solid ${colors.gray[500]};
`;

const FilterDropdown = ({ title, children, color, shade }) => {
  const [ref, showViewMenu, setShowViewMenu] = useOutsideClick(false);
  return (
    <FilterDropdownContainer ref={ref}>
      <Menu.Toggle onClick={() => setShowViewMenu((prev) => !prev)} color={color} shade={shade}>
        {title}
      </Menu.Toggle>
      {showViewMenu && (
        <DropdownItemContainer className="no-scrollbar">
          {typeof children === "function" ? children({ setOpen: setShowViewMenu }) : children}
        </DropdownItemContainer>
      )}
    </FilterDropdownContainer>
  );
};

const LabeledMenuItem = convertComponent(Menu.Item, styled.label());

const ToggleItem = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: space-between;
  width: 100%;
`;

const FilterTaggableChild = ({ checked, onChange, children, isSingle }) => {
  const [id] = useState(uniqueId("toggle-item-"));
  return (
    <LabeledMenuItem htmlFor={id} isSingle={isSingle}>
      <ToggleItem>
        {children}
        <Toggle
          checked={checked}
          id={id}
          onChange={() => {
            onChange?.();
          }}
        />
      </ToggleItem>
    </LabeledMenuItem>
  );
};

export {
  TextLabel,
  LargeTextLabel,
  TextInput,
  SmallTextInput,
  TextArea,
  Dropdown,
  FilterDropdown,
  FilterTaggableChild,
  PasswordInput,
};
