import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Children, cloneElement, forwardRef } from "react";
import { isElement } from "react-dom/test-utils";

import { colors, constants, grid } from "../../styles/theme";
import { SmallCircularTextButton } from "../common/buttons";
import { OutlinedIcon } from "../common/icons";
import { Subheading } from "../common/typography";
import { ellipsisOverflow } from "./css-snippets";

const elevation = {
  left: "2px 1px 3px -2px #8b92a21a, 2px 4px 8px 0px #8b92a21a, inset 2px 1px 3px -2px #8b92a21a, inset 2px 4px 8px 0px #8b92a21a",
  right: {
    first: "-2px 1px 3px -2px #8b92a21a, -2px 4px 8px 0px #8b92a21a",
    subsequent: "inset -2px 1px 3px -2px #8b92a21a, inset -2px 4px 8px 0px #8b92a21a",
  },
};

const elevationCssLeft = () => css`
  box-shadow: ${elevation.left};
`;

const elevationCssRight = (props) => css`
  box-shadow: ${props.depth === 0 ? elevation.right.first : `${elevation.right.first}, ${elevation.right.subsequent}`};
`;

const BaseContainer = styled.div`
  margin-bottom: 1px;
  ${(props) => !props.show && "visibility: hidden;"}
  background-color: ${(props) => props.backgroundColor};
  text-align: left;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${(props) => props.align === "left" && elevationCssLeft()}
  ${(props) => props.align === "right" && elevationCssRight(props)}
`;

const StaticContainer = styled(BaseContainer)`
  max-width: ${(props) => (props.show ? "424px" : 0)};
  flex: 1 0 ${(props) => (props.show ? "374px" : 0)};
  margin-inline: 32px -32px;
  transition:
    max-width 0.3s,
    flex-basis 0.3s;
  position: relative;
  ${(props) => props.zIndex && `z-index: ${props.zIndex};`}
`;

const FixedContainer = styled(BaseContainer)`
  width: ${(props) => props.parentWidth ?? props.width};
  height: 100%;
  position: fixed;
  top: 0px;
  z-index: calc(3 - ${(props) => props.depth});
  transition:
    left 0.3s,
    right 0.3s,
    visibility 0.3s;

  ${(props) =>
    props.align === "left" &&
    css`
      left: ${props.show
        ? `calc(${constants.sideMenuWidth} + (${props.width}) * ${props.depth})`
        : `calc(${constants.sideMenuWidth} - ${props.width} + (${props.width} * ${props.depth}))`};
    `}

  ${(props) =>
    props.align === "right" &&
    !props.parentWidth &&
    css`
      right: ${props.show
        ? `calc(${props.width} * ${props.depth})`
        : `calc(-${props.width} + (${props.width} * ${props.depth}))`};
    `}

	${(props) =>
    props.align === "right" &&
    props.parentWidth &&
    css`
      right: ${props.show
        ? `calc(${props.parentWidth} * ${props.depth})`
        : `calc(-${props.width} + (${props.parentWidth} * ${props.depth}))`};
    `}
`;

const Backdrop = styled.div`
  ${(props) => !props.show && "visibility: hidden;"}
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
`;

const GridContainerOuter = styled(Backdrop)`
  padding-inline: calc(${constants.sideMenuWidth} + ${grid.desktop.gutter}) ${grid.desktop.margin};
  display: grid;
  grid-template-columns: repeat(${grid.desktop.cols}, 1fr);
  column-gap: ${grid.desktop.gutter};
`;

const GridContainerInner = styled(BaseContainer)`
  right: ${(props) => (props.show ? `-${grid.desktop.margin}` : `calc(-100% - ${grid.desktop.margin})`)};
  grid-column: ${(props) => props.gridColumn};
  ${(props) => props.align === "right" && `padding: 0 ${grid.desktop.margin};`}
  transition: 0.35s;
  position: relative;
`;

const SidePanel = forwardRef(
  (
    {
      show,
      children,
      type = "fixed",
      depth = 0,
      align = "left",
      backgroundColor = "white",
      width = "464px",
      gridColumn = "4 / -1",
      zIndex = null,
      parentWidth,
      close = () => {},
    },
    ref
  ) => {
    switch (type) {
      case "static":
        return (
          <StaticContainer
            align={align}
            backgroundColor={backgroundColor}
            depth={depth}
            onClick={(e) => e.stopPropagation()}
            ref={ref}
            show={show}
            width={width}
            zIndex={zIndex}
          >
            {children}
          </StaticContainer>
        );
      case "grid": {
        return (
          <GridContainerOuter onClick={close} show={show} ref={ref}>
            <GridContainerInner
              align={align}
              backgroundColor={backgroundColor}
              gridColumn={gridColumn}
              onClick={(e) => e.stopPropagation()}
              show={show}
            >
              {children}
            </GridContainerInner>
          </GridContainerOuter>
        );
      }
      case "fixed":
      default:
        return (
          <FixedContainer
            align={align}
            backgroundColor={backgroundColor}
            depth={depth}
            onClick={(e) => e.stopPropagation()}
            parentWidth={parentWidth}
            show={show}
            width={width}
            ref={ref}
          >
            {children}
          </FixedContainer>
        );
    }
  }
);

const Header = styled.div`
  padding: ${(props) => props.padding};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Heading = styled(Subheading)`
  ${ellipsisOverflow}
`;

const CloseButton = styled(SmallCircularTextButton)`
  margin-right: -5px;
`;

SidePanel.Header = ({ padding, close, children }) => (
  <Header padding={padding ?? "27px 24px 19px 26px"}>
    <Heading>{children}</Heading>
    <CloseButton color="gray" onClick={close} shade={900}>
      <OutlinedIcon name="close" />
    </CloseButton>
  </Header>
);

const DarkBackdrop = styled(Backdrop)`
  background-color: ${(props) => (props.show ? `${colors.purple[1000]}4D` : "transparent")};
  transition: background-color 0.3s;
`;

SidePanel.DarkBackdrop = ({ show, close, children }) => {
  return (
    <DarkBackdrop onClick={close} show={show}>
      {children}
    </DarkBackdrop>
  );
};

export default SidePanel;
