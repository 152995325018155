import { gql } from "@apollo/client";

const UPDATE_ACCOUNT = gql`
  mutation updateAccount($data: updateAccountInput!, $accountId: ID) {
    updateAccount(data: $data, accountId: $accountId) {
      id
      name
      phoneNumber
      location
      industry
      website
      timezone
      address
      enableLeadSending
      emailLeads
    }
  }
`;

const ADD_EMAIL_LEAD = gql`
  mutation addEmailLead($accountId: ID!, $email: String!) {
    addEmailLead(accountId: $accountId, email: $email)
  }
`;

const REMOVE_EMAIL_LEAD = gql`
  mutation removeEmailLead($accountId: ID!, $index: Int) {
    removeEmailLead(accountId: $accountId, index: $index)
  }
`;

const INVITE_USER = gql`
  mutation invite($data: inviteInput!) {
    invite(data: $data)
  }
`;

const ACCEPT_INVITE = gql`
  mutation acceptInvite($accountId: ID!) {
    acceptInvite(accountId: $accountId)
  }
`;

const UPDATE_SUBSCRIPTION = gql`
  mutation updateSubscription($accountId: ID!, $hostedPageId: ID!) {
    updateSubscription(accountId: $accountId, hostedPageId: $hostedPageId)
  }
`;
export { UPDATE_ACCOUNT, ADD_EMAIL_LEAD, REMOVE_EMAIL_LEAD, INVITE_USER, ACCEPT_INVITE, UPDATE_SUBSCRIPTION };
