import styled from "@emotion/styled";

import { colors, elevation } from "../../styles/theme";

const Outline = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 47px;
  height: 38px;
  border-radius: 4px;
  outline: none;
  z-index: 0;
`;

const Container = styled.div`
  width: 39px;
  text-align: left;
  flex-shrink: 0;
  display: inline-block;
  position: relative;
  z-index: 0;

  &:focus-within {
    ${Outline} {
      outline: 2px solid ${colors.gray[900]};
    }
  }
`;

const Circle = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 30px;
  width: 30px;
  background-color: transparent;
  color: ${colors.gray[900]};
  border-radius: 100px;
  z-index: 0;
`;

const Ball = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 14px;
  width: 12px;
  margin: 6px;
  background-color: ${colors.gray[800]};
  border-radius: 100px;
  display: block;
  transition: right 0.2s ease-in 0s;
  z-index: 1;
`;

const Label = styled.label`
  cursor: pointer;
  height: 24px;
  background-color: transparent;
  border-radius: 20px;
  display: block;
  overflow: hidden;
  outline: 2px solid ${colors.gray[800]};
  outline-offset: -2px;
  transition:
    outline-color 0.1s ease-in 0s,
    background-color 0.1s ease-in 0s;
  position: relative;
  z-index: 1;
`;

const Checkbox = styled.input`
  position: absolute;
  opacity: 0;
  left: -999px;

  &:checked {
    ~ ${Circle} {
      right: -2.25px;
    }

    + ${Label} {
      background-color: ${colors.green[800]};
      outline: 2px solid ${colors.green[800]};

      ${Ball} {
        right: 0px;
        width: 18px;
        margin: 3px;
        background-color: white;
      }
    }

    + ${Label}:hover {
      ${Ball} {
        background-color: ${colors.green[100]};
      }

      ~ ${Circle} {
        background-color: ${colors.green[100]};
      }
    }

    + ${Label}:active {
      ${Ball} {
        top: -1.5px;
        right: -1.5px;
        width: 21px;
        height: 21px;
        background-color: ${colors.green[100]};
        transition: all 0s;
      }

      ~ ${Circle} {
        right: -3.5px;
        background-color: ${colors.green[100]};
      }
    }

    &:focus {
      + ${Label} ${Ball} {
        background-color: ${colors.green[100]};
      }

      ~ ${Circle} {
        background-color: ${colors.green[100]};
      }
    }
  }

  &:not(:checked) {
    ~ ${Circle} {
      left: -2.25px;
    }

    + ${Label} {
      &:hover {
        ${Ball} {
          background-color: ${colors.gray[900]};
        }

        ~ ${Circle} {
          background-color: #1c1b1f14;
        }
      }

      &:active {
        ${Ball} {
          top: -4.5px;
          left: -4.5px;
          width: 21px;
          height: 21px;
          background-color: ${colors.gray[900]};
          transition: all 0s;
        }

        ~ ${Circle} {
          left: -3.5px;
          background-color: #1c1b1f1f;
        }
      }
    }
  }

  &:disabled {
    + ${Label} {
      pointer-events: none;
    }

    &:checked {
      + ${Label} {
        background-color: ${colors.gray[300]};
        outline: 2px solid ${colors.gray[300]};

        ${Ball} {
          right: 0px;
          width: 18px;
          margin: 3px;
          background-color: white;
          box-shadow: ${elevation[100]};
        }
      }
    }

    &:not(:checked) {
      + ${Label} {
        outline: 2px solid #1c1b1f1f;

        ${Ball} {
          background-color: #1c1b1f61;
        }
      }
    }
  }
`;

const Toggle = ({
  id = Math.random().toString(36).substring(7),
  name,
  defaultChecked,
  checked,
  onChange,
  disabled,
}) => (
  <Container>
    <Checkbox
      checked={checked}
      defaultChecked={defaultChecked}
      disabled={disabled}
      id={id}
      name={name}
      onChange={onChange}
      readOnly={!onChange}
      type="checkbox"
    />
    <Label htmlFor={id} onClick={(e) => e.stopPropagation()}>
      <Ball />
    </Label>

    <Circle />
    <Outline />
  </Container>
);

export default Toggle;
