import { useEffect, useRef, useState } from "react";

const useOutsideClick = (initialShow, onOutsideClick) => {
  const containerRef = useRef(null);
  const [show, setShow] = useState(initialShow);

  useEffect(() => {
    setShow(initialShow);
  }, [initialShow]);

  const onClick = (e) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setShow(false);
      onOutsideClick?.(e);
    }
  };

  useEffect(() => {
    if (show) document.addEventListener("click", onClick, true);
    return () => document.removeEventListener("click", onClick, true);
  }, [show, containerRef.current]);

  return [containerRef, show, setShow];
};

export default useOutsideClick;
