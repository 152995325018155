import { makeVar } from "@apollo/client";

export const CRM_FIELD_OPTION_COLORS = makeVar(["cyan", "blue", "orange", "purple", "green", "red"]);
export const SELECTED_CRM_TAB = makeVar(null);
export const EDITING_CRM_TAB_FIELD = makeVar(null);
export const EDITING_CRM_CUSTOMER = makeVar(null);

export const FILTER_STATUS = makeVar(null);
export const FILTER_SOURCES = makeVar([]);

export const resetCrmReactiveVars = () => {
  SELECTED_CRM_TAB(null);
  EDITING_CRM_TAB_FIELD(null);
};
