import { makeVar } from "@apollo/client";
import { v4 as UUIDv4 } from "uuid";

import { resetAccountReactiveVars } from "./account";
import { resetCrmReactiveVars } from "./crm";
import { resetDriveReactiveVars } from "./drive";
import { resetMetricsReactiveVars } from "./metrics";
import { resetPlatformReactiveVars } from "./platform";
import { resetPostReactiveVars } from "./post";

export const SOCKET_ID = makeVar(UUIDv4());

export const resetReactiveVars = () => {
  SOCKET_ID(UUIDv4());
  resetAccountReactiveVars();
  resetCrmReactiveVars();
  resetPlatformReactiveVars();
  resetDriveReactiveVars();
  resetMetricsReactiveVars();
  resetPostReactiveVars();
};
