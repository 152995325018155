import { gql } from "@apollo/client";

const GET_ACCOUNT_USER_ROLES = gql`
  query accountUserRoles($accountId: ID!) {
    accountUserRoles(accountId: $accountId) {
      id
      name
    }
  }
`;

const GET_ACCOUNT_USERS = gql`
  query accountUsers($accountId: ID!) {
    accountUsers(accountId: $accountId) {
      userId
      accountId
      roleId
      role
      name
      email
      preferredName
      inviterUserId
      isAcceptedInvite
      isRevokedInvite
    }
  }
`;

const CHECK_SUBSCRIPTION = gql`
  query checkSubscription($accountId: ID!) {
    checkSubscription(accountId: $accountId) {
      success
      data
    }
  }
`;

const GET_CUSTOMER_ID_BY_ACCOUNT = gql`
  query getCustomerIdByAccount($accountId: ID!) {
    getCustomerIdByAccount(accountId: $accountId)
  }
`;

export { GET_ACCOUNT_USER_ROLES, GET_ACCOUNT_USERS, CHECK_SUBSCRIPTION, GET_CUSTOMER_ID_BY_ACCOUNT };
