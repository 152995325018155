import { makeVar } from "@apollo/client";

export const CURRENT_FOLDER = makeVar(null);
export const CURRENT_MEDIA_ITEM = makeVar(null);
export const MEDIA_UPLOAD_PROGRESS = makeVar(new Map());

export const SET_MEDIA_UPLOAD_PROGRESS = (id, status) => {
  const map = MEDIA_UPLOAD_PROGRESS();
  if (status === 100 || status === null) {
    if (map.has(id)) map.delete(id);
  } else {
    map.set(id, status);
  }
  MEDIA_UPLOAD_PROGRESS(new Map([...map.entries()]));
};

export const resetDriveReactiveVars = () => {
  CURRENT_FOLDER(null);
  CURRENT_MEDIA_ITEM(null);
};
