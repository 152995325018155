import { makeVar } from "@apollo/client";
import { v4 as UUIDv4 } from "uuid";

import makeVarPersisted from "./helper";
import { SOCKET_ID, resetReactiveVars } from "./root";

export const CURRENT_ACCOUNT = makeVarPersisted(null, "CURRENT_ACCOUNT");
export const SHOW_NOTIFICATION = makeVar(false);

export const SET_CURRENT_ACCOUNT = (value, client) => {
  if ((value === null, value !== CURRENT_ACCOUNT())) {
    client.resetStore();
    resetReactiveVars();
  }
  SOCKET_ID(UUIDv4());
  CURRENT_ACCOUNT(value);
};

export const resetAccountReactiveVars = () => {
  CURRENT_ACCOUNT(null);
};
